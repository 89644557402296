import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css"
import App from './App';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import * as Sentry from "@sentry/react";

const container = document.getElementById('root');
const root = createRoot(container);

Sentry.init({
  dsn: "https://21bac00adb4268c18373e300de25e53c@o1240163.ingest.us.sentry.io/4507256366432256",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SESSION_RATE),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.3,// If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();
