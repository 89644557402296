import React from "react"
import { ProgressBar } from "react-bootstrap";

import "./Navigation.css";
import { Button } from "../../../components/Button";

import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import { TitleH2 } from "../../../components/TitleH2";

export default function Navigation (props) {

    const t = useSelector(selectTranslations)

    const askEndExam = () => {
        props?.handleEndTraining()
    }

    return (
        <div className="col-3 navigation_bar pb-5">
            <TitleH2 className="mt-2" style={{color:'#181818'}}><strong>{t.question.question} {props.questions?.findIndex(q => q.question_id === props.navigationData?.currentQuestion)+1}</strong>/{props.navigationData.questions_count}</TitleH2>

            <ProgressBar className="time_pbar mt-2" now={props.questions?.findIndex(q => q.question_id === props.navigationData?.currentQuestion)+1} max={props.navigationData.questions_count} />
            <Button className="mt-5 mb-4" onClick={askEndExam}>{t.training.end_training}</Button>
        </div>
    )
}