import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useLocation } from "react-router";
import { useEffect, useState, useRef } from "react";
import { setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice";
import { Link, useNavigate } from "react-router-dom";

import correct_img from '../../assets/images/questions/correct.svg'
import missing_img from '../../assets/images/questions/missing.svg'
import wrong_img from '../../assets/images/questions/wrong.svg'
import timer_img from './assets/images/timer.svg'
import score_img from './assets/images/score.svg'
import questions_img from '../../assets/images/questions.svg'
import hand_img from '../../assets/images/high-five-hand.svg'
import sad_img from '../../assets/images/sad.svg'


import { TitleH1 } from "../../components/TitleH1";
import { TitleH3 } from "../../components/TitleH3";
import { Button } from "../../components/Button";
import { normalizeModules } from "./examUtils";
import ThresholdSheet from "./_ofa_components/ThresholdSheet";
import { selectAppInfo } from "../../services/utils/appInfoSlice";
import { selectUserData } from "../../features/User/userSlice";

export default function ExamEndScreen(){

    const [examData, setExamData] = useState(null)
    const [step, setStep] = useState(0)

    const appInfo = useSelector(selectAppInfo)
    const userInfo = useSelector(selectUserData)

    const t = useSelector(selectTranslations)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showThresholdSheet, setShowThresholdSheet] = useState(false)

    const closeThresholdSheet = () =>{
        if(!userInfo?.groups?.includes(appInfo?.personalizations?.ofa_settings?.retry_exam_group_id))
            navigate('/',{replace: true})
        setShowThresholdSheet(false)
    }

    useEffect(()=>{
        dispatch(setNavbarHide(true))
        if(location.state && location.state?.results){
            const {_questions} = normalizeModules(location.state?.results?.modules)
            setExamData({
                ...location.state.results,
                questions: _questions,
                papers: location?.state?.examData?.papers
            })
        } else if (location.state){
            setExamData(location.state?.examData)
        } else
            navigate('/exams', {replace:true})
        dispatch(setLoading(false))
        // eslint-disable-next-line
    },[location])

    useEffect(() => {
        dispatch(setNavbarHideMobile(true))
        dispatch(setNavbarHide(true))
        setTimeout(()=>{
            setShowThresholdSheet(true)
        },3000)
    }, [examData?.exam_id])

    const totalTime = () => {
        let _total = 0
        examData?.modules?.forEach((m)=>
            _total += m.consumed_time
        )
        return _total
    }

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    return (
        <section id="examPreviewScreen">
            <div className="h-100">
            {
                <div className="steps">
                    <div className={`bullet ${step === 0 ? 'active' : ''} `}></div>
                    {
                        examData?.exam?.visible_scoreboard ?
                            <div className={`bullet ${step === 2 ? 'active' : ''} `}></div>
                        : null
                    }
                </div>
            }
            {examData && step === 0 ?
            <div className="d-flex flex-column justify-content-between h-100">
                <div style={{maxWidth:"500px", minWidth:"320px"}} className="mx-auto">
                    {
                        appInfo?.is_ofa ?
                        <>
                            {examData?.thresholds?.find(t=>t.threshold_type === "certificate_generation")?.has_been_passed ?
                                <img src={hand_img} style={{width:'45px'}} className="mx-auto d-block" alt=''/>
                                : 
                                <img src={sad_img} style={{width:'45px'}} className="mx-auto d-block" alt=''/>
                            }
                        </> :
                        <img src={hand_img} style={{width:'45px'}} className="mx-auto d-block" alt=''/>
                    }
                    <TitleH1 className="text-center">
                        {
                            appInfo?.is_ofa ?
                                <>
                                    {examData?.thresholds?.find(t=>t.threshold_type === "certificate_generation")?.has_been_passed ?
                                        t.ofa.exam.exam_passed_title : t.ofa.exam.exam_not_passed_title
                                    }
                                </> :
                            t.exam.exam_ended
                        }
                    </TitleH1>
                    <div className="border mt-4 mb-2 p-3 pt-4" style={{borderRadius:"16px", background:'#FFFDFC'}}>
                        <TitleH3  className="text-center"><img src={score_img} alt="" className="me-2"/> {t.exam.scoring}</TitleH3>
                        <div className="mt-2">
                            <div className="exam_score text-center">
                                <strong style={{color:'#181818'}}>{examData?.total_score}</strong> {t.exam.points}
                            </div>
                            <div className="d-flex justify-content-around mt-2">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img style={{verticalAlign:"sub"}} src={correct_img} className="mb-1" alt="" />
                                    <strong>{examData?.correct_count}</strong> 
                                    <span>{t.exam.correct}</span>
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center"><img style={{verticalAlign:"sub"}} src={missing_img} className="mb-1" alt="" /><strong>{examData?.missing_count}</strong><span>{t.exam.missing}</span> </div>
                                <div className="d-flex flex-column justify-content-center align-items-center"><img style={{verticalAlign:"sub"}} src={wrong_img} className="mb-1" alt="" /><strong>{examData?.wrong_count}</strong> <span>{t.exam.wrong}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="border mt-3 mb-2 p-3 pt-4" style={{borderRadius:"16px", background:'#FFFDFC'}}>
                        <TitleH3 className="text-center"><img src={timer_img} alt="" className="me-2"/>{t.exam.duration}</TitleH3>
                        <div className="mb-2  mt-3  ">
                            <div className="exam_score  text-center">
                                <strong style={{color:'#181818'}}>{totalTime()} min</strong>/ {examData.time}
                            </div>
                        </div> 
                    </div>
                </div>
                <div>
                    {
                        examData?.questions?.length > 0 ?
                            <Link to={'/exams/review/'} state={examData} replace={true}>
                                <Button className="mx-auto w-100">
                                    <img src={questions_img} alt="" className="me-1"/>
                                    {t.exam.questions_review}
                                </Button>
                            </Link>
                        : null
                    }
                    <Link 
                        to={ appInfo?.is_ofa && !examData?.thresholds?.find(t=>t.threshold_type === "certificate_generation")?.has_been_passed ? '/' : '/exams/'} 
                        replace={true}
                    >
                        <Button color="secondary" className="mx-auto mt-2 w-100">
                            {appInfo?.is_ofa && !examData?.thresholds?.find(t=>t.threshold_type === "certificate_generation")?.has_been_passed ? t.buttons.keep_training : t.buttons.exit}
                        </Button>
                    </Link>
                </div>
            </div>
            : null}
            {
                appInfo.is_ofa && examData?.thresholds?.find(t=>t.threshold_type === "certificate_generation") ?
                    <ThresholdSheet show={showThresholdSheet} closeBottomSheet={closeThresholdSheet} thresholds={examData?.thresholds} /> : null
            }
            </div>
        </section>
    )
}