import {
  createBrowserRouter,
  Route,
  Routes,
  useLocation
} from "react-router-dom";

import RootScreen from "../../screens/RootScreen/RootScreen";
import LoginScreen from "../../screens/LoginScreen/LoginScreen";
import { ProtectedRoute } from "./ProtectedRoute";
import ConfirmToken from "../../screens/LoginScreen/ConfirmToken";
import ErrorScreen from "../../screens/ErrorScreen/ErrorScreen";
import OnBoarding from "../../screens/LoginScreen/Onboarding/Onboarding";
import AdminWaitingScreen from "../../screens/LoginScreen/AdminWaitingScreen";
import { TENANT } from "../../config/constants";
import AccountScreen from "../../screens/AccountScreen/AccountScreen";
import ExamsScreen from "../../screens/ExamScreen/ExamsScreen";
import NotificationScreen from "../../screens/NotificationScreen/NotificationScreen";
import ExamPreviewScreen from "../../screens/ExamScreen/ExamPreviewScreen";
import ExamStartScreen from "../../screens/ExamScreen/ExamStartScreen";
import ExamPerformScreen from "../../screens/ExamScreen/ExamPerformScreen";
import ExamEndScreen from "../../screens/ExamScreen/ExamEndScreen";
import ExamsHistoryScreen from "../../screens/ExamScreen/ExamsHistoryScreen";
import ExamReviewScreen from "../../screens/ExamScreen/ExamReviewScreen";
import ExamScoreboard from "../../screens/ExamScreen/ExamScoreboard";
import FolderScreen from "../../screens/FolderScreen/FolderScreen";
import TrainingPerformScreen from "../../screens/TrainingScreen/TrainingPerformScreen";
import TrainingEndScreen from "../../screens/TrainingScreen/TrainingEndScreen";
import VideoPlayer from "../../screens/FolderScreen/VideoPlayer";
import ConfirmTFToken from "../../screens/LoginScreen/ConfirmTFToken";
import ContentViewer from "../../screens/FolderScreen/ContentViewer";
import { useEffect } from "react";
// Router creations
export const router = createBrowserRouter([
  {
    // Login Routes
    path: "/login/*",
    children: [
      // New blog index route
        { index: true, element: <LoginScreen />},
        // Sub Route
        { path: "thefaculty_login", element: <ConfirmTFToken />},
        { path: "confirm_account_email", element: <ConfirmToken />},
        { path: "waiting_approval", element: <ProtectedRoute skipCheck={'approval'}><AdminWaitingScreen /></ProtectedRoute>},
        { path: "onboarding", element: <ProtectedRoute skipCheck={'data'}><OnBoarding /></ProtectedRoute>},
    ],
  },
  { path: "*", 
    Component: Root,
  },
],
  {
    basename: `/${process.env.REACT_APP_TENANT ? "" : `${TENANT}`}`
  }  
);


function Root(e) {
  // 2️⃣ `BrowserRouter` component removed, but the <Routes>/<Route>
  // component below are unchanged
  return (
    <>
      <RootScreen>
          <ScrollToTop />
          <Routes>
            <Route index element={<ProtectedRoute><FolderScreen /></ProtectedRoute>} />
            <Route path="/folder/:folder_id" element={<ProtectedRoute><FolderScreen /></ProtectedRoute>} />
            <Route path="/training/" >
                <Route path="perform/:folder_id" element={<ProtectedRoute><TrainingPerformScreen /></ProtectedRoute>} />
                <Route path="results" element={<ProtectedRoute><TrainingEndScreen /></ProtectedRoute>} />
            </Route>
            <Route path="/video/" >
                <Route path="play/:folder_id/:video_id" element={<ProtectedRoute><VideoPlayer /></ProtectedRoute>} />
            </Route>
            <Route path="/file/" >
                <Route path="view/:folder_id/:file_id" element={<ProtectedRoute><ContentViewer /></ProtectedRoute>} />
            </Route>
            <Route path="/exams/" >
                <Route index element={<ProtectedRoute ><ExamsScreen /></ProtectedRoute>} />
                <Route path="preview/:exam_id" element={<ProtectedRoute><ExamPreviewScreen/></ProtectedRoute>} />
                <Route path="perform/" element={<ProtectedRoute><ExamPerformScreen/></ProtectedRoute>} />
                <Route path="start_module/" element={<ProtectedRoute><ExamStartScreen/></ProtectedRoute>} />
                <Route path="exam_results/" element={<ProtectedRoute><ExamEndScreen/></ProtectedRoute>} />
                <Route path="history/" element={<ProtectedRoute><ExamsHistoryScreen/></ProtectedRoute>} />
                <Route path="review/:exam_id" element={<ProtectedRoute><ExamReviewScreen/></ProtectedRoute>} />
                <Route path="review" element={<ProtectedRoute><ExamReviewScreen/></ProtectedRoute>} />
                <Route path="scoreboard/:exam_id" element={<ProtectedRoute><ExamScoreboard/></ProtectedRoute>} />
            </Route>
            <Route path="/notifications" element={<ProtectedRoute><NotificationScreen /></ProtectedRoute>} />
            <Route path="/account" element={<ProtectedRoute ><AccountScreen /></ProtectedRoute>} >
                <Route path="info" element={<ProtectedRoute><AccountScreen/></ProtectedRoute>} />
            </Route>
            
            <Route path="*" element={<ErrorScreen />} />
        </Routes>
      </RootScreen>
    </>
  );
}


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}