import { isMobile } from "react-device-detect"
import Sheet from 'react-modal-sheet';
import Modal from 'react-bootstrap/Modal';
import {Button} from "../Button";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";

import './modalStyle.css'

export default function BottomSheet (props) {

    const {isOpen, onClose, overrideMobileCheck=false, children, showExit=true} = props
    const t = useSelector(selectTranslations)

    return (
        <>
        { isMobile || overrideMobileCheck ? 
            <Sheet isOpen={isOpen} onClose={onClose} detent='content-height'>
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        {children}
                    </Sheet.Content>
                </Sheet.Container>
                    <Sheet.Backdrop onTap={onClose}/>
            </Sheet>
        : 
            <Modal
                show={isOpen}
                onHide={onClose}
                keyboard={false}
                centered
            >
                <Modal.Body>
                    {children}
                    {showExit && <Button className="mt-2 mx-auto" onClick={onClose} color="secondary">{t.modals.close}</Button>}
                </Modal.Body>
            </Modal>
        }
        </>
    )
}