import "./PaginationControl.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import { useState, useRef } from "react";
import { Button } from "../../../components/Button";
import icon_missing_answer from '../../../assets/images/questions/missing.svg'
import icon_correct_answer from '../../../assets/images/questions/correct.svg'
import icon_wrong_answer from '../../../assets/images/questions/wrong.svg'
import up_img from '../../../assets/images/questions/controller_up.svg'
import { isCorrectAnswer } from "../examUtils";
import NavigationMobileReview from "./NavigationMobileReview";


export default function PaginationMobile(props) {

    const t = useSelector(selectTranslations)
    const proceedButton = useRef(null)
    const backButton = useRef(null)

    const ref = useRef()

    const [openBottomSheet, setOpenBottomSheet] = useState(false)

    const changeQuestion = (index, scroll=false) => {
        props.handleChangeQuestion(index);
        proceedButton?.current?.blur();
        backButton?.current?.blur();
        if(scroll)
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
    }

    const questionIndex = () => props.questions.findIndex(q => q.question_id === props.navigationData?.currentQuestion)

    return ( 
        <>
        <div className="exam_pagination_control">
            <div className="arrow_pagination_control d-flex justify-content-center pt-1" onDrag={()=>setOpenBottomSheet(true)} onClick={()=>setOpenBottomSheet(true)}>
                <img src={up_img} alt="" />
            </div>
            <div className="exam_pagination_control__content">
            {
                props.userModules[props?.navigationData?.currentModule]?.module_name ? 
                    <div className="mb-0">
                        <p className="text-black mb-0" style={{fontWeight: '800'}}>Modulo {props.userModules[props?.navigationData?.currentModule].module_number +1}: {props.userModules[props?.navigationData?.currentModule].module_name} </p>
                    </div>
                : ""
            }
            <div>
            {
                !props.navigationData.isLinearModuleNavigation ?
                    <div className="">
                        <div className="questions_nav">
                            {
                                props?.questions?.map((question, index) => {
                                    if(question.module_number === props.navigationData.currentModule){
                                        let has_answer = ((question.selected_answer_number !== null && question.selected_answer_number !== undefined && !isNaN(question.selected_answer_number)) || (question.selected_answer_numbers !== null && question.selected_answer_numbers !== undefined && question?.selected_answer_numbers?.length !== 0 )) ? true : false;
                                        return (
                                            <div key={index} className={"question_el_external " 
                                                                + (question.isBlockQuestion ? "block_wrapped " : "") 
                                                                + (question.isFirstBlock ? "block_wrapped_first " : "") 
                                                                + (question.isLastBlock ? "block_wrapped_last " : "") }
                                                ref={question.question_id === props.navigationData.currentQuestion ? ref : null}                
                                                >
                                                <div key={index} onClick={() => {changeQuestion(question.question_id, question.module_number)}} className={"question_el " + (has_answer ? "answered " : "") + (question.is_uncertain ? "is_uncertain " : "" ) + (question.question_id === props.navigationData.currentQuestion ? "current " : "") + (props.navigationData.is_linear_module_navigation && (props.navigationData?.current_module_number !== question.module_number) ? "disabled_question " : "")}>
                                                    {parseInt(index)+1}
                                                    {
                                                    !has_answer ?
                                                        <img src={icon_missing_answer} className="answer_navigation_result" />
                                                    : (isCorrectAnswer(question) ? <img src={icon_correct_answer} className="answer_navigation_result" /> : <img src={icon_wrong_answer} className="answer_navigation_result" />)
                                                    }
                                                </div>
                                                
                                            </div>
                                        )
                                    } else return null
                                })
                            }
                        </div>
                    </div>
                : <div className="questions_nav">
                    {
                        props?.questions?.map((question, index) => {
                            if(question.module_number === props.navigationData.currentModule){
                                let has_answer = ((question.selected_answer_number !== null && question.selected_answer_number !== undefined && !isNaN(question.selected_answer_number)) || (question.selected_answer_numbers !== null && question.selected_answer_numbers !== undefined && question?.selected_answer_numbers?.length !== 0 )) ? true : false;
                                return (
                                    <div key={index} 
                                        className={"question_el_external " + (question.isBlockQuestion ? "block_wrapped " : "") + (question.isFirstBlock ? "block_wrapped_first " : "") + (question.isLastBlock ? "block_wrapped_last " : "") }
                                        ref={question.question_id === props.navigationData.currentQuestion ? ref : null}    >
                                        <div key={index} onClick={() => {changeQuestion(question.question_id, question.module_number)}} 
                                            className={"question_el " + (has_answer ? "answered " : "") + (question.is_uncertain ? "is_uncertain " : "" ) + (question.question_id === props.navigationData.currentQuestion ? "current " : "") + (props.navigationData.is_linear_module_navigation && (props.navigationData?.current_module_number !== question.module_number) ? "disabled_question " : "")}
                                                        
                                        >
                                            {parseInt(index)+1}
                                            {
                                            !has_answer ?
                                                <img src={icon_missing_answer} className="answer_navigation_result" />
                                            : (isCorrectAnswer(question) ? <img src={icon_correct_answer} className="answer_navigation_result" /> : <img src={icon_wrong_answer} className="answer_navigation_result" />)
                                            }
                                        </div>
                                        
                                    </div>
                                )
                            } else return null
                        })
                    }
                </div>
            }
            </div>
            <div className="row align-items-center mt-2 justify-content-end">
                <div className="col-12 col-md-9 mb-3 d-flex align-items-center justify-content-between">
                    <Button className="white" 
                                disabled={questionIndex() === 0} 
                                style={questionIndex() === 0 ? {opacity:'0.1'} : {}
                                } 
                                onClick={() => { changeQuestion( props.questions[parseInt(questionIndex() - 1)]?.question_id, true)}}>
                        {t.question.previous}
                    </Button>
                    <Button className="white" 
                        disabled={props.questions && 
                                    ( parseInt(questionIndex()) + 1 === props.questions.length )
                                } 
                        style={props.questions && 
                                ( parseInt(questionIndex()) + 1 === props.questions.length ) ? {opacity:'0.1'} : {}
                            } 
                        onClick={() => { changeQuestion( props.questions[parseInt(questionIndex() + 1)]?.question_id, true)}} 
                    >
                        {t.question.following}
                    </Button>
                </div>
            </div>
            </div>
        </div>
        <NavigationMobileReview
                open={openBottomSheet}
                setOpen={setOpenBottomSheet}
                questions={props?.questions} 
                userModules={props.userModules} 
                navigationData={props.navigationData} 
                handleChangeQuestion={props.handleChangeQuestion} 
                handleCloseExam={props.handleCloseExam} 
                handleCloseModule={props.handleCloseModule} 
                handleChangeModule={props.handleChangeModule} 
                remainingQuestions={props.remainingQuestions}
                isReview={true}
            />
        </>
    )
}