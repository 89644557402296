// Slice where the loading state is

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  hideNavbar: false,
  hideNavbarMobile: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNavbarHide: (state, action) => {
      state.hideNavbar = action.payload;
    },
    setNavbarHideMobile: (state, action) => {
      state.hideNavbarMobile = action.payload;
    }
  },
});

export const { setLoading, setNavbarHide, setNavbarHideMobile } = globalSlice.actions;

export default globalSlice.reducer;

export const selectLoadingState = (state) => state.global.loading; 
export const selectNavbarHideState = (state) => state.global.hideNavbar; 
export const selectNavbarHideMobileState = (state) => state.global.hideNavbarMobile; 