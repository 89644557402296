import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: "",
  text: "",
  show: false,
  cta_text: "",
  redirect: "",
};

export const errorSlice = createSlice({
  name: 'errorManager',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showError: (state, action) => {
      console.log(action);
        state.title = action.payload.title || ""
        state.text = action.payload.text || ""
        state.cta_text = action.payload.cta_text || ""
        state.redirect = action.payload.redirect || ""
        state.show = true
    },
    hideErrorModal: (state) => {
        state.show = false
    },
  },
});

export const { showError, hideErrorModal } = errorSlice.actions;

export const selectErrorData = (state) => state.errorManager;

export default errorSlice.reducer;
