import React, {useState, useEffect, useRef, useContext} from "react";
import { useTimer } from 'react-timer-hook';
import { highlightSelection, eraseHighlightedSelection, insertMyText } from "./readerUtils";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import './PaperReader.css'
import arrow_left from '../assets/images/arrow_previous.svg'
import bookmark_img from '../../../assets/images/reader/bookmark.svg'
import highlighter_img from '../../../assets/images/reader/marker.svg'
import eraser_img from '../../../assets/images/reader/eraser.svg'
import font_img from '../../../assets/images/reader/font.svg'
import bookmark_active_img from '../../../assets/images/reader/bookmark_active.svg'
import highlighter_active_img from '../../../assets/images/reader/marker_active.svg'
import eraser_active_img from '../../../assets/images/reader/eraser_active.svg'
import font_active_img from '../../../assets/images/reader/font_active.svg'

import Lottie from 'lottie-react';
import timerAnimation from '../assets/timer.json'
import { isMobile } from "react-device-detect";

export default function PaperReader(props) {

    const [fontSize, setFontSize] = useState(20);
    const [selectedTool, setSelectedTool] = useState(null)
    let paperRef = useRef()

    const GetFromLocalStorage = function (key) {
        return JSON.parse(localStorage.getItem(key))
    }

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp:props.expiryTime, onExpire: () => {} });
    const seconds_ref = useRef(seconds);
    const minutes_ref = useRef(minutes);
    const hours_ref = useRef(hours);

    const formatText = (original_text = "") => {
        return original_text?.replaceAll("\n", '<br /><br />')?.replaceAll("$$", "$")
    }

    const tools = [
        { 
            type: 'bookmark',
            image: bookmark_img,
            active_image: bookmark_active_img    
        },
        { 
            type: 'highlighter',
            image: highlighter_img,
            active_image: highlighter_active_img    
        },
        { 
            type: 'eraser',
            image: eraser_img,
            active_image: eraser_active_img    
        },
        { 
            type: 'font',
            image: font_img,
            active_image: font_active_img    
        }
    ]

    useEffect(() => {
        seconds_ref.current = seconds;
        minutes_ref.current = minutes;
        hours_ref.current = hours;
    }, [seconds])

    useEffect(()=>{
        if(!props.isEnded)
            restart(props.expiryTime);
    },[props.expiryTime])

    const handleUpdatePaperStatus = () => {
        let papers = props.questionsPapersData.slice();
        for (let i in papers) {
            if (papers[i]?.question_number == props?.paperData?.question_number) {
                papers[i]["paper"] = paperRef.current.innerHTML;
                props.setQuestionsPapersData(
                    papers
                );
                break;
            }
        }
    }

    const toggleSelectedTool = (tool) => {
        if(tool == selectedTool)
            setSelectedTool(null)
        else
            setSelectedTool(tool)
    }

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    const onMouseUp = (e) => {
        const s = window.getSelection().toString();
        switch (selectedTool) {
            case 'bookmark':
                insertMyText()
            break;
            case 'highlighter':
                highlightSelection(); 
            break;
            case 'eraser':
                eraseHighlightedSelection();
            break;
            default: 
                return;
        }
        handleUpdatePaperStatus()
    };

    const handleChangeFont = (e) => {
        let papers = props.questionsPapersData.slice();
        for (let i in papers) {
            if (papers[i].question_number == props?.paperData?.question_number) {
                papers[i]["fontSize"] = e;
                props.setQuestionsPapersData(
                    papers
                );
                break;
            }
        }
    }

    


    return (
        <div className="reader_page">
            <div className="container">
                <div className="reader_header">
                    <span onClick={props?.handleCloseReader} className="backButtonReader">
                        <div className="arrow_navigation me-1"><img src={arrow_left} /> </div>
                        {!isMobile ? <span>Domanda {props.data.virtual_question_number}</span> : null}
                    </span>
                    <div className={`d-flex flex-column flex-md-row mt-0 mt-md-3 align-items-center ${props.isEnded ? "justify-content-end" : "justify-content-between"}`}>
                        {!props.isEnded ?
                            <div className="d-flex align-items-top justify-content-start mb-2 mb-lg-0">
                                <Lottie
                                    animationData={timerAnimation}
                                    autoplay={true}
                                    loop={100000}
                                    style={isMobile ? {width:"20px"} : {width:"35px"}}
                                    className="d-block me-2"
                                />
                                <div className={"exam_timer "}>
                                    <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
                                </div>
                            </div> : ""
                        }
                        <div className="reader_controllerd ">
                            <div className="reader_controller d-flex">
                                {
                                    tools.map((tool)=>{
                                        return(
                                            <div key={tool.type} className={"tool " + ( selectedTool == tool.type ? 'active' : '')} onClick={()=>{toggleSelectedTool(tool.type)}}>
                                                { selectedTool != tool.type ?
                                                    <img src={tool.image} />
                                                :  <img src={tool.active_image} /> }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="reader_content" onMouseUp={onMouseUp} >
                    <p style={{whiteSpace: "pre-wrap", fontSize: props?.paperData?.fontSize+"px", lineHeight: "120%" }} dangerouslySetInnerHTML={{ __html: formatText(props?.paperData?.paper) }} ref={paperRef} />
                </div>
                { selectedTool === 'font' ?
                    <div className="reader_font_selector">
                        
                        <div className="slider_container">
                            <span className="me-2" style={{fontSize:"12px"}}>A</span>
                            <Slider 
                                min={12}
                                max={32}
                                step={4}
                                dots={true}
                                value={props?.paperData?.fontSize}
                                onChange={handleChangeFont}
                            />
                            <span className="ms-2" style={{fontSize:"32px"}}>A</span>
                        </div>
                        
                    </div> : ""
                }
            </div>
        </div>
    )
}