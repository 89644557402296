import { useSelector } from 'react-redux'

import light_img from '../../../assets/images/questions/light.svg'

import { selectTranslations } from '../../../services/i18n/i18nSlice'
import { isMobile } from 'react-device-detect'
import BottomSheet from '../../../components/_modals/BottomSheet'
import { Modal } from 'react-bootstrap'
import { Button } from '../../../components/Button'
import { TitleH3 } from '../../../components/TitleH3'
import { Text } from '../../../components/Text'
import SecureImage from '../../../components/_partials/SecuredImage'
import { API_URL } from '../../../config/constants'

import '../../../components/_modals/modalStyle.css'

export default function QuestionPill (props){

    const {pill, show, onClose} = props
    const t = useSelector(selectTranslations)

    return (
        <>
            {isMobile ? 
                <BottomSheet isOpen={show} onClose={onClose} showExit={false}>
                    {
                        pill.image_url &&
                            <SecureImage imageUrl={`${API_URL}${pill.image_url}`} className="w-100" />
                    }
                    <div className='d-flex px-4 mb-3 align-items-center'>
                        <img src={light_img} />
                        <TitleH3 className='ms-2'>{t.question.pill}</TitleH3>
                    </div>
                    <Text className='px-4 pill_text'>
                        {pill?.text}
                    </Text>
                    <Button onClick={onClose} className='mx-auto w-100 mt-3 mb-5'>{t.buttons.skip}</Button>
                </BottomSheet>
            :
                <>
                    <Modal
                        show={show}
                        onHide={onClose}
                        className='come-from-modal right'
                    >
                        <Modal.Body>
                            {
                                pill.image_url &&
                                    <SecureImage imageUrl={`${API_URL}${pill.image_url}`} className="w-100" />
                            }
                            <div className='d-flex mt-4 px-4 mb-3 align-items-center'>
                                <img src={light_img} />
                                <TitleH3 className='ms-2'>{t.question.pill}</TitleH3>
                            </div>
                            <Text className='px-4 pill_text'>
                                {pill?.text}
                            </Text>
                            <Button onClick={onClose} className='mx-auto w-100 mt-3 mb-4'>{t.buttons.skip}</Button>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    )
}