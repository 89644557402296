import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice";

import PaginationControl from "./_components/PaginationControl";
import img_close from '../../assets/images/close.svg'

import { fetchGetDetailedExam, fetchGetExam } from "./ExamsApi";
import { showError } from "../../features/Error/errorSlice";
import { extractPapers, normalizeModules } from "./examUtils";
import PaperReader from "./_components/PaperReader";
import { isMobile } from "react-device-detect";
import { selectAppPersonalizations } from "../../services/utils/appInfoSlice";
import { API_URL } from "../../config/constants";
import EndModal from "../../components/_modals/EndModal";
import NavigationReview from "./_components/NavigationReview";
import QuestionReview from "./_components/QuestionReview";
import PaginationReviewMobile from "./_components/PaginationReviewMobile";

export default function ExamReviewScreen(){

    const [examData, setExamData] = useState(null)

    const t = useSelector(selectTranslations)
    const appInfo = useSelector(selectAppPersonalizations)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {exam_id} = useParams()

    const [confirmModal, setConfirmModal] = useState({
        show: false,
        title: "",
        text: "",
        onClose: null,
        onConfirm: null,
        onSecondary: null,
        confirmCta: "",
        secondaryCta: ""
    })


    const [openReader, setOpenReader] = useState(false)

    const [navigationData, setNavigationData] = useState({
        currentQuestion: null,
        currentModule: 0,
        isLinearModuleNavigation: true,
        globalTimeout: null,
        modulesCount: 0,
        timeoutId: null
    });


    const navigateBack = () => {
        if(exam_id) {
            navigate('/exams', {replace: true})
        } else {
            navigate('/exams/exam_results', {replace:true, state:{examData: examData}})
        }
    }

    //Navigation Handler
    const handleChangeQuestion = (question_id) => {
        const _question = examData?.questions?.find(q=>q.question_id===question_id)
        setNavigationData({
            ...navigationData,
            currentQuestion: question_id,
            currentModule: navigationData?.isLinearModuleNavigation ? 0 : _question?.module_number
        });
    }
    const handleChangeModule = (module_id) => {
        setNavigationData({
            ...navigationData,
            currentModule: module_id
        });
    }

    const loadExamData = async () => {
        const response = await fetchGetDetailedExam({exam_id:exam_id})
        if(response.success){
            let _examData = response.data
            const {_questions, _papers} = normalizeModules(_examData.modules)
            _examData.questions = _questions
            _examData.papers = _papers
            setExamData(_examData)
            setNavigationData({
                ...navigationData,
                currentQuestion: _examData.questions[0].question_id,
                currentModule: 0,
                isLinearModuleNavigation: _examData?.exam?.time ? false : true,
                modulesCount: _examData?.exam?.modules_count,
            })
            dispatch(setLoading(false))
        } else {
            dispatch(showError({
                title: t.exam.errors.start,
                text: t.exam.errors.start_text,
                redirect: "/exams"
            }))
        }
    }

    useEffect(()=>{
        dispatch(setLoading(true))
        if(location.state){
            setExamData(location.state)
            setNavigationData({
                ...navigationData,
                currentQuestion: location.state.questions[0].question_id,
                currentModule: 0,
                isLinearModuleNavigation: false,
                modulesCount: location.state?.modules_count,
            })
            dispatch(setLoading(false))
        } else if(exam_id){
            loadExamData()
        }  else
            navigate('/exams',{replace:true})
        
        // eslint-disable-next-line
    },[location])

    useEffect(() => {
        dispatch(setNavbarHideMobile(true))
        dispatch(setNavbarHide(true))
    }, [examData?.exam_id, exam_id])

    return (
        <section id="examPerformScreen">
            { !isMobile ?
            <div className='exam_header'>
                <img src={`${API_URL}${appInfo.logo_url}`} className="d-none d-md-block" style={{height:'50px'}} />
            </div> : null}
            {
                isMobile ? 
                <div className='exam_header'>
                    <img src={img_close} alt="" className="pointer" onClick={navigateBack} />
                </div> : null
            }
            {examData && examData.modules && examData.modules[0]? 
                <>
                    {
                        openReader ?
                            <PaperReader 
                                isEnded={true}
                                expiryTime={navigationData?.globalTimeout} 
                                data={examData?.questions.find(q => q.question_id === navigationData?.currentQuestion) || null} 
                                questionsPapersData={examData?.papers} setQuestionsPapersData={null} 
                                paperData={examData?.papers?.find(p => p.question_number == examData?.questions?.find(q => q.question_id === navigationData?.currentQuestion)?.question_number && p.module_number == examData?.questions?.find(q => q.question_id === navigationData?.currentQuestion)?.module_number) || null} 
                                handleCloseReader={()=>setOpenReader(false)} />
                        : ""
                    }
                    <section id="examContent">
                        <div className="row ">
                            {!isMobile ?
                                <NavigationReview 
                                    questions={examData?.questions} 
                                    userModules={examData?.modules} 
                                    navigationData={navigationData} 
                                    handleChangeQuestion={handleChangeQuestion} 
                                    handleCloseExam={null} 
                                    handleCloseModule={null} 
                                    handleChangeModule={handleChangeModule}
                                    consumed_time={examData.modules[0].consumed_time} 
                                    saveGlobalTimeout={null} 
                                    navigateBack={navigateBack}
                                />
                            : null}
                            <QuestionReview 
                                data={examData?.questions.find(q => q.question_id === navigationData.currentQuestion)} 
                                handleAnswerQuestion={null} 
                                handleOpenReader={()=>setOpenReader(true)} 
                                navigationData={navigationData}
                            />
                        </div>
                    </section>
                    {isMobile ?
                        <PaginationReviewMobile 
                            questions={examData?.questions} 
                            userModules={examData?.modules} 
                            navigationData={navigationData} 
                            handleChangeQuestion={handleChangeQuestion} 
                            handleCloseExam={null} 
                            handleCloseModule={null} 
                            handleChangeModule={handleChangeModule} 
                        />
                    : 
                        <PaginationControl 
                            navigationData={navigationData} 
                            handleChangeQuestion={handleChangeQuestion} 
                            questions={examData?.questions} 
                        />
                    }
                </>
            : "" }
            <EndModal props={confirmModal}/>
        </section>
    )
}