import { useDispatch, useSelector } from "react-redux";
import { hideErrorModal, selectErrorData } from "./errorSlice";
import { Modal } from "react-bootstrap";

import img_warning from '../../assets/images/warning.svg'
import img_close from '../../assets/images/close.svg'
import { TitleH2 } from "../../components/TitleH2";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { selectTranslations } from "../../services/i18n/i18nSlice";

export default function GlobaErrorModal () {

    const modalData = useSelector(selectErrorData)
    const t = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onConfirm = () => {
        dispatch(hideErrorModal())
        navigate(modalData?.redirect, {replace:true})
    }

    return(
        <Modal
            show={modalData?.show}
            onHide={() => dispatch(hideErrorModal())}
            keyboard={false}
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-end">
                    <img src={img_close} alt="" className="pointer" onClick={() => dispatch(hideErrorModal())} />
                </div>
                <div className="d-flex justify-content-center">
                    <img src={img_warning} alt="" className="mx-center" />
                </div>
                <TitleH2 className="text-center mt-2">{modalData?.title}</TitleH2>
                <Text className="text-center mb-4">{modalData?.text}</Text>
                { ( modalData?.redirect && modalData?.cta_text ) ?
                    <Button className="mt-2 w-100 mx-auto" onClick={onConfirm} color="primary">{modalData?.cta_text || t.modals.confirm}</Button>
                 :
                <Button className="mt-2 w-100 mx-auto" onClick={() => dispatch(hideErrorModal())} color="secondary">{ t.modals.close}</Button>
                }
            </Modal.Body>
        </Modal>
    )
}