import CallServer from '../../api/CallServerV2';
export async function fetchGetExams (params = {}) {
    return await CallServer({
        endpoint: '/user/get_exams',
        params: params,
        needAuthenticate: true,
        isGet: true,
        retryIfFailed: true
    })
} 

export async function fetchGetUserExams (params = {}) {
    return await CallServer({
        endpoint: '/user/get_user_exams',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
}

export async function fetchGetPendingExams (params = {}) {
    return await CallServer({
        endpoint: '/user/get_pending_user_exams',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 

export async function fetchGetExam (params = {}) {
    return await CallServer({
        endpoint: '/user/get_exam',
        params: params,
        needAuthenticate: true,
        isGet: true,
        retryIfFailed: true
    })
} 

export async function fetchGetDetailedExam (params = {}) {
    return await CallServer({
        endpoint: '/user/get_detailed_user_exam',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 

export async function fetchStartExam (params = {}) {
    return await CallServer({
        endpoint: '/user/start_exam',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 

export async function fetchEndExam (params = {}) {
    return await CallServer({
        endpoint: '/user/end_exam',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 

export async function fetchStartModule (params = {}) {
    return await CallServer({
        endpoint: '/user/start_module',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 

export async function fetchUpdateConsumedTime (params = {}) {
    return await CallServer({
        endpoint: '/user/update_consumed_time',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 

export async function fetchRespondToQuestion (params = {},answer_data) {
    return await CallServer({
        endpoint: '/user/respond_to_question',
        params: params,
        body:answer_data,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 


export async function fetchGetScoreboard (params = {}) {
    return await CallServer({
        endpoint: '/user/get_exam_scoreboard',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 