import { useEffect, useState } from "react"
import "./Question.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import { Form, OverlayTrigger, Popover, FormCheck } from "react-bootstrap";

import correct_img from '../../../assets/images/questions/correct.svg'
import wrong_img from '../../../assets/images/questions/wrong.svg'
import missing_img from '../../../assets/images/questions/missing.svg'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import SecureImage from "../../../components/_partials/SecuredImage";
import { isMobile } from "react-device-detect";
import { Text } from "../../../components/Text";
import { isCorrectAnswer } from "../examUtils";
import { TitleH1 } from "../../../components/TitleH1";

export default function QuestionReview(props){

    const t = useSelector(selectTranslations)
    const [HasAnswer,setHasAnswer] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)

    const formatText = (original_text = "") => {
        return original_text?.replaceAll("\n", '<br />')?.replaceAll("$$","$")
    }

    useEffect(()=>{
        setImageLoaded(false)
    },[props?.data?.image_url])

    return (
        <div className="col-12 col-md-9 exam_question_container px-3 px-md-4 pb-5">
            {
                props.data? 
                    <>
                        <TitleH1>{t.exam.questions_review}</TitleH1>
                        {!isMobile ? <Text className="question_number">{t.question.question} {parseInt(props.data.virtual_question_number)} </Text> : null}
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: props.data.text }} />
                        {
                            props.data.paper && props.data.virtual_question_number != undefined ?
                                <div className="reader_preview" onClick={props.handleOpenReader}>
                                    <p style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{ __html: formatText(props.data.paper) }} />
                                </div>
                            : ""
                        }
                        { props.data.image_url ?
                            <> 
                                <Zoom>
                                    <SecureImage src={props.data.image_url} alt="Question Image" className="mt-3" />
                                </Zoom>
                                
                                <small className="d-block text-secondary">{t.question.click_on_image}</small>
                            </>
                        : ""}
                        {
                            props.data.additional_material_instructions ? <p className="mt-2 text-secondary">{props.data.additional_material_instructions}</p> : ""
                        }
                        <Text className="mb-0 mt-4">{ props.data?.question_type === "multi" || props.data?.question_type === "survey" ? t.question.your_answers : t.question.your_answer}</Text>
                        <div className="answers_list">
                            {props.data.answers.map((answer) => {
                                if(( props.data.selected_answer_numbers && props.data?.selected_answer_numbers?.includes(parseInt(answer.answer_number)) )  || (answer.answer_number == props?.data?.selected_answer_number))
                                    return (
                                        <label className={"answer " + (answer.is_correct_answer ? "correct_answer" : "wrong_answer")}  key={answer.answer_number} >
                                            
                                            <FormCheck>
                                                <FormCheck.Input  
                                                    type={"radio"}
                                                    className="hidden_radio"
                                                    name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                    id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                    value={answer.answer_number} 
                                                    readOnly
                                                    checked={props.data.selected_answer_numbers !== null && props.data.selected_answer_numbers?.includes(parseInt(answer.answer_number)) ? true : false}
                                            />
                                                { answer.is_correct_answer ? <img src={correct_img} /> : <img src={wrong_img} /> }
                                                <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} dangerouslySetInnerHTML={{ __html: answer.text }} />
                                            </FormCheck>
                                        </label>
                                    )
                            })}
                            {
                                props.data?.selected_answer_number === null || (props.data?.selected_answer_numbers && props.data?.selected_answer_numbers?.length === 0) ?
                                <label className={"answer missing_answer"}  >       
                                    <FormCheck>
                                        <FormCheck.Input  
                                            type={"radio"}
                                            className="hidden_radio"
                                            name={`answer-missing`}
                                            id={`answer-missing`}
                                            readOnly
                                    />
                                        <img src={missing_img} />
                                        <FormCheck.Label dangerouslySetInnerHTML={{ __html:t.question.missing_answer }} />
                                    </FormCheck>
                                </label> : null
                            }
                        </div>
                        {
                            !isCorrectAnswer(props.data) ?
                        <>
                        <Text className="mb-0 mt-4">{t.question.correct_answers}</Text>
                        <div className="answers_list">
                            {props.data.answers.map((answer) => {
                                if( answer.is_correct_answer && ((!props.data?.selected_answer_numbers?.includes(parseInt(answer.answer_number)) ) && (answer.answer_number !== props?.data?.selected_answer_number)))
                                    return (
                                        <label className={"answer correct_answer"} key={answer.answer_number}>
                                            
                                            <FormCheck>
                                                <FormCheck.Input  
                                                    type={"radio"}
                                                    className="hidden_radio"
                                                    name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                    id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                    value={answer.answer_number} 
                                                    readOnly
                                                    checked={props.data.selected_answer_numbers !== null && props.data.selected_answer_numbers?.includes(parseInt(answer.answer_number)) ? true : false}
                                            />
                                                <img src={correct_img} />
                                                <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} dangerouslySetInnerHTML={{ __html: answer.text }} />
                                            </FormCheck>
                                        </label>
                                    )
                            })}
                        </div>
                        </> : null }
                    </>
                : ""
            }
        </div>
    )
}