import "./PaginationControl.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import { useState, useRef } from "react";
import { Button } from "../../../components/Button";

export default function PaginationControl(props) {

    const t = useSelector(selectTranslations)
    const proceedButton = useRef(null)
    const backButton = useRef(null)

    const changeQuestion = (index) => {
        props.handleChangeQuestion(index);
        proceedButton?.current?.blur();
        backButton?.current?.blur();
    }

    const questionIndex = () => props.questions.findIndex(q => q.question_id === props.navigationData?.currentQuestion)

    return ( 
        <div className="exam_pagination_control">
            <div className="row align-items-center justify-content-end">
                <div className="col-12 col-md-9 mb-3 d-flex align-items-center justify-content-between">
                    <Button className="white" 
                                disabled={questionIndex() === 0} 
                                style={questionIndex() === 0 ? {opacity:'0.1'} : {}
                                } 
                                onClick={() => { changeQuestion( props.questions[parseInt(questionIndex() - 1)]?.question_id)}}>
                        {t.question.previous}
                    </Button>
                    <Button className="white" 
                        disabled={props.questions && 
                                    ( parseInt(questionIndex()) + 1 === props.questions.length )
                                } 
                        style={props.questions && 
                                ( parseInt(questionIndex()) + 1 === props.questions.length ) ? {opacity:'0.1'} : {}
                            } 
                        onClick={() => { changeQuestion( props.questions[parseInt(questionIndex() + 1)]?.question_id)}} 
                    >
                        {t.question.following}
                    </Button>
                </div>
            </div>
        </div>
    )
}