import MainLoader from './MainLoader';

import './RootScreen.css'


export default function LoaderScreen ({children}) {

    return(
        <div id="loaderScreen">
            <MainLoader /> 
        </div>
    )
}
