import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchGetUserData, fetchGetUserFields, fetchUpdateOnlineDate, fetchUpdateUserData } from './userApi';
import { showError } from '../Error/errorSlice';
import { DEFAULT_FIELDS, TENANT } from '../../config/constants';
import { setLoading } from '../../services/global/globalSlice';

import * as Sentry from '@sentry/react';

const initialState = {
  user_data: null,
  user_fields: null,
  is_saved: false,
}

export const loadUserProfileData = createAsyncThunk(
  'userInfo/loadUserProfileData',
  async (_,thunkAPI) => {

      const response = await fetchGetUserData();
      if (response?.success) {
            thunkAPI.dispatch(setLoading(false))
            return response.data
      } else {
        //TO-DO: Aggiungere dispatch errori 
        //dispatch error
      }
  }
);
export const loadUserFieldsData = createAsyncThunk(
  'userInfo/loadUserFieldsData',
  async () => {
      const response = await fetchGetUserFields();
      if (response?.success) {
          return response.data
      } else {
        //TO-DO: Aggiungere dispatch errori 
        //dispatch error
      }
  }
);
export const updateUserData = createAsyncThunk(
  'userInfo/updateUserData',
  async (props, thunkAPI) => {
        const {user_data , show_message} = props
        const response = await fetchUpdateUserData(user_data);
        if (response?.success) {
            return response.data
        } else {
            thunkAPI.dispatch(showError(response.error))
        }
  }
);
export const updateOnlineDate = createAsyncThunk(
  'userInfo/updateOnlineDate',
  async (props, thunkAPI) => {
        const response = await fetchUpdateOnlineDate();
        if (response?.success) {
            setTimeout(()=>thunkAPI.dispatch(updateOnlineDate()),300000)
            return response.data
        } else {
        console.error(response.error)
        }
  }
);

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    updateUserField: (state, action) => {
        const field_id = action.payload.field_id
        let value = action.payload.value
        if(action.payload.field_type === "choices")
            if(Array.isArray(value))
                value = value.map((v)=>v.value)
            else
                value = [value.value]
        if(action.payload.type === "default")
            state.user_data[field_id] = value
        else{
            let idf = state.user_data.extra_fields.findIndex((e) => e.field_id === field_id);
            if(idf >= 0)
                state.user_data.extra_fields[idf].values = value
            else
                state.user_data.extra_fields.push({field_id:field_id, values:value})
        }
        state.is_saved = false
    },
    resetUserData: (state, action) => {
        state.user_data = null
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(loadUserFieldsData.fulfilled, (state, action) => {
            state.user_fields = action.payload
        })
        .addCase(loadUserProfileData.fulfilled, (state, action) => {
            if(action.payload){
                state.user_data = action.payload
                state.is_saved = true
                Sentry.setUser({user_id: action.payload?.user_id, tenant: TENANT});
            }
        })
        .addCase(updateUserData.fulfilled, (state, action) => {
            if(action.payload){
                state.user_data = action.payload
                state.is_saved = true
            }
        })
  },
})
export default userInfoSlice.reducer
export const { updateUserField, resetUserData } = userInfoSlice.actions;

export const selectUserInfo = (state) => state?.userInfo;
export const selectUserData = (state) => state?.userInfo?.user_data;
export const selectUserFields = (state) => state?.userInfo?.user_fields;
export const checkUserFields = (state) => {
    let has_missing = false
    if(state?.userInfo?.user_fields && state?.userInfo?.user_data)
        state?.userInfo?.user_fields?.forEach((f)=>{
            if (f.field_level === 0){
                if(DEFAULT_FIELDS.includes(f.field_id)){
                    if (state?.userInfo?.user_data[f?.field_id] === "" || state?.userInfo?.user_data[f?.field_id] === null)
                        has_missing = true
                }else{
                    if (!state?.userInfo?.user_data?.extra_fields?.find(e => (e.field_id === f.field_id && (e.values !== "" && e.values !== null))))
                        has_missing = true
                } 
            }
        })
    return has_missing
}