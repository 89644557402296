import { useRef, useState } from "react"
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import UploadServer from "../../api/UploadServer";
import Spinner from 'react-bootstrap/Spinner';
import { randomUid } from "../../utils/utils";

export default function UploadButton({uploadId=randomUid(), type='', folder_id=null, onUpload = null, accept = '', children}){

    const t = useSelector(selectTranslations)
    const [isUploading, setIsUploading] = useState(false)

    const uploadRef = useRef()
    const uploadFile = async (event) => {
        setIsUploading(true)
        if(type === ""){
            toast.error(t.upload.missing_file_type, {
                position: "top-right"
            })
            return;
        }

        if (uploadRef.current.files[0]) {
            let reader = new FormData();
            reader.append("file",uploadRef.current.files[0]);
            let response = await UploadServer({
                params: {
                    'file_type': type,
                    ...(folder_id && {
                        'folder_id': folder_id
                    })
                },
                endpoint: '/upload_file',
                body: reader
            });
            if (response.success) {
                
                if(response?.data?.file_id){
                    onUpload(`/get_file?file_id=${response?.data?.file_id}`)
                }
                else{
                    toast.error(t.upload.error_upload, {
                        position: "top-right"
                    })
                    return;
                }
            } else {
                if (response.error === "Duplicates in user_id"){
                    toast.error(t.upload.error_upload, {
                        position: "top-right"
                    })
                    return;
                } else{
                    toast.error(t.upload.error_upload, {
                        position: "top-right"
                    })
                    return;
                }
            }
            setIsUploading(false)
        } else {
            toast.error(t.upload.error_upload, {
                position: "top-right"
            })
            setIsUploading(false)
            return;
        }     
    }

    return (
        <>
            <label htmlFor={`upload_input__${uploadId}`} className="upload_button colored d-flex align-items-center">
                {isUploading ? 
                    <Spinner />
                : <>
                    {children ? children : t.upload.upload}
                </>}
            </label>
            <input id={`upload_input__${uploadId}`} type="file" ref={uploadRef} disabled={isUploading} hidden className="w-100 mt-2 standardInput" onChange={uploadFile} accept={accept} />
        </>
    )
}