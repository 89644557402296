import { createSlice } from '@reduxjs/toolkit';
import { defaultLang, supportedLangs} from "../../config/i18nConfig";

import it from './lang/it.json'

const initialState = {
  lang: defaultLang, // "en" when app loads
  supportedLangs: { ...supportedLangs },
  // We'll keep our translations in Redux to start.
  // Later on we'll move them to their own files so
  // that they're easier to manage.
  translations: {
    it: it
  },
};
export const i18nSlice = createSlice({
  name: "i18n",
  initialState,
});
export const selectTranslations = (state) => state.i18n.translations[state.i18n.lang];
export const selectCurrentLang = (state) => state.i18n.lang;

export default i18nSlice.reducer;