import CallServer from '../../api/CallServerV2';
export async function fetchVerifyAccountEmail (email) {
    return await CallServer({
        params: { 'user_email': email },
        endpoint: '/verify_account_email',
        needAuthenticate: false,
        retryIfFailed: true,
    })
} 
export async function fetchConfirmAccountEmail (email,pin) {
    return await CallServer({
        params: { 'user_email': email, 'security_pin': pin },
        endpoint: '/confirm_account_email',
        needAuthenticate: true,
        retryIfFailed: true,
    })
} 

export async function fetchConfirmAccountEmailWithToken (login_token) {
    return await CallServer({
        params: { 'security_token': login_token },
        endpoint: '/confirm_account_email',
        needAuthenticate: true,
        retryIfFailed: true,
    })
} 

export async function fetchConfirmAccountEmailWithThefacultyToken (login_token) {
    return await CallServer({
        params: { 'thefaculty_token': login_token },
        endpoint: '/verify_thefaculty_token',
        needAuthenticate: true,
        retryIfFailed: true,
    })
} 

export async function fetchUpdateAuthToken () {
    return await CallServer({
        endpoint: '/verify_cookie',
        needAuthenticate: true,
        retryIfFailed: true,
    })
} 

export async function fetchRemoveAuthtoken () {
    return await CallServer({
        endpoint: '/logout',
        needAuthenticate: true,
        retryIfFailed: true,
    })
} 