import { useDispatch, useSelector } from "react-redux"
import { selectUserInfo, updateUserData, updateUserField } from "../../../features/User/userSlice"
import { selectTranslations } from "../../../services/i18n/i18nSlice"
import { Avatar } from "../../../components/_partials/Avatar"
import {Button} from "../../../components/Button"
import { toast } from 'react-toastify';

import av_upload from '../../../assets/images/avatar_upload.svg' 
import {TitleH2} from "../../../components/TitleH2"

export default function AvatarSelectModule (props) {

    const userInfo = useSelector(selectUserInfo)
    const t = useSelector(selectTranslations)
    const dispatch = useDispatch()

    const saveChanges = () => {
        let user_data = [
            {field_id: 'profile_image_url', field_values: userInfo?.user_data?.profile_image_url},
            {field_id: 'profile_avatar_id', field_values: userInfo?.user_data?.profile_avatar_id}
        ]
        dispatch(updateUserData({user_data: user_data})).then(data => {
            if (data.payload) {
                toast.success(t.account.data_updated)
            } else {
                toast.error(t.error.error_saving)
            }
        })  
    }

    const selectAvatar = (a_id) => {
        dispatch(updateUserField({value: "", field_id: 'profile_image_url', type:'default'}))
        dispatch(updateUserField({value: a_id, field_id: 'profile_avatar_id', type:'default'}))
    }

    return (
        <div id="userAvatarModule">
            <div className="page_title">
                <img src={av_upload} />
                <TitleH2 className="mb-0">{t.account.choose_avatar}</TitleH2>
            </div>
            <div className="avatars mt-4">
                {[...Array(12).keys()].map((a)=>{
                    return(
                        <Avatar key={a} avatar_id={a} className={!userInfo?.user_data?.profile_image_url && userInfo?.user_data?.profile_avatar_id === a ? 'selected pointer' : "pointer"} onClick={()=>selectAvatar(a)} />
                    )
                })}
            </div>
            <Button className="mt-4 mx-auto mx-md-0" onClick={saveChanges} disabled={userInfo.is_saved}>
                {t.buttons.save}
            </Button>
        </div>
    )
}