import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate, redirect, useParams } from "react-router";
import { fetchGetScoreboard } from './ExamsApi';
import { selectUserData } from '../../features/User/userSlice';
import { useDispatch, useSelector } from "react-redux";

import './assets/Exam.css'
import SecureImage from '../../components/_partials/SecuredImage';
import { Avatar } from '../../components/_partials/Avatar';
import { API_URL } from '../../config/constants';

import score_img from './assets/images/score.svg'
import Scoreboard from './_components/Scoreboard';


export default function ExamScoreboard(props) {

    const location = useLocation()
   
    const { exam_id } = useParams()

    return(
        <div>
            <Scoreboard examId={exam_id} />
        </div>
    )
}