import link_img from '../../../assets/images/folders/link.svg'
import img_arrow from '../../../assets/images/right.svg'


export default function Link ({link}) {
    return (
        <a className="link" href={link.link_url} target='_blank'>
            <div className='d-flex justify-content-between'>
                <span>
                    <img src={link_img} alt=""/>
                    <span className='ms-2 '>{link.name}</span>
                </span>
                <img src={img_arrow} alt='' />
            </div>
            
        </a>
    )
}