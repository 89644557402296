import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useLocation } from "react-router";
import { useEffect, useState, useRef } from "react";
import { setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice";
import { Link, useNavigate } from "react-router-dom";

import correct_img from '../../assets/images/questions/correct.svg'
import score_img from '../../assets/images/score.svg'

import Lottie from 'lottie-react';
import mobileAnimation from '../../assets/images/confetti_mobile.json'
import desktopAnimation from '../../assets/images/confetti_desktop.json'

import { TitleH1 } from "../../components/TitleH1";
import { TitleH3 } from "../../components/TitleH3";
import { Button } from "../../components/Button";

import hand_img from '../../assets/images/high-five-hand.svg'
import { isMobile } from "react-device-detect";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";


import './Training.css'
import { TitleH2 } from "../../components/TitleH2";

export default function TrainingEndScreen(){

    const [trainingData, setTrainingData] = useState(null)
    const t = useSelector(selectTranslations)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(setLoading(true))
        if(location.state && location.state?.trainingData){
            setTrainingData(location.state?.trainingData)
        } else
            navigate('/', {replace:true})
        dispatch(setLoading(false))
        // eslint-disable-next-line
    },[location])

    useEffect(()=>{
        dispatch(setNavbarHideMobile(true))
        dispatch(setNavbarHide(false))
    },[trainingData?.training_id])


    return (
        <section id="trainingResultScreen" className="fade-in-animation"> 
            
            {trainingData ?
            <>
                <div style={{maxWidth:"500px", minWidth:"320px", minHeight:"100%"}} className="mx-auto text-center d-flex flex-column justify-content-between" >
                    <Lottie 
                        animationData={isMobile ? mobileAnimation : desktopAnimation}
                        autoplay={true}
                        className="confetti_animation" 
                        loop={false}
                    />
                    <div className="trainign_end_top">
                        <img src={hand_img} style={{width:'56px'}} alt="" className="mx-auto"/>
                        <TitleH1 className="text-center">{t.training.trainig_ended}</TitleH1>
                        <div className="border mt-4 mb-2 p-4 " style={{borderRadius:"16px",background:'#fff'}}>
                            <TitleH3  className="text-center"><img src={score_img} alt="" className="me-2"/> {t.exam.scoring}</TitleH3>
                            <div className="mt-2">
                                <div className="d-flex justify-content-center align-items-center mb-3">
                                    <TitleH2 className="mb-0"><strong>{trainingData?.correct_count}</strong> </TitleH2><img style={{verticalAlign:"middle"}} src={correct_img} className="ms-2 me-2" alt="" />  <span style={{fontWeight:'500'}}> su {trainingData?.questions_count}</span>
                                </div>
                                <ProgressBar
                                    
                                    percent={trainingData?.correct_count*100/trainingData.questions_count - 5}
                                    filledBackground="linear-gradient(90deg, #436094 0%, #5075B7 100%)"
                                    height='14px'
                                    stepPositions={Array.from(Array(trainingData?.questions_count), (v,i) => i*100/trainingData?.questions_count +7)}
                                >
                                    {
                                        Array.from(Array(trainingData?.questions_count), (v,i) => 
                                        <Step position={i*100/trainingData?.questions_count}>
                                            {({ accomplished }) => (
                                                <div className="d-block" key={i}>
                                                    <div style={{ display:'block', height:'10px', width:'10px', background:`${accomplished ? '#F4F4F4' : '#F4F4F4'}`, borderRadius:'50%' }} width='10' className='mx-auto'/>
                                                </div>
                                            )}
                                        </Step>
                                        )
                                    }
                                    
                                </ProgressBar>
                            </div>
                        </div>
                    </div>
                    <div className="results_footer">
                        <Link to={`/folder/${trainingData?.folder_id}`}>
                            <Button className="w-100 mx-auto">
                                {t.training.back_to_folder}
                            </Button>
                        </Link>
                        <Link to={`/training/perform/${trainingData?.folder_id}`}>
                            <Button className="w-100 mx-auto mt-3" color="secondary">
                                {t.training.retry}
                            </Button>
                        </Link>
                    </div>
                </div>
            </>
            : null}
        </section>
    )
}