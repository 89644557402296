import React from "react"
import { ProgressBar } from "react-bootstrap";

import "./Navigation.css";

import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import { TitleH3 } from "../../../components/TitleH3";

export default function NavigationMobile (props) {

    const t = useSelector(selectTranslations)

    return (
        <div className="col-12 mt-4">
            <TitleH3 className="mt-2" style={{color:'#181818'}}><strong>{t.question.question} {props.questions?.findIndex(q => q.question_id === props.navigationData?.currentQuestion)+1}</strong>/{props.navigationData.questions_count}</TitleH3>
            <ProgressBar className="time_pbar mt-2" now={props.questions?.findIndex(q => q.question_id === props.navigationData?.currentQuestion)+1} max={props.navigationData.questions_count} />
        </div>
    )
}