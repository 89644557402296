import { useSelector } from "react-redux";
import { selectAppPersonalizations } from "../services/utils/appInfoSlice";
import styled from 'styled-components'


const DefaultH3 = styled.h3`
  /* Adapt the colors based on primary prop */
  color: ${props => props.$color || '#181818'};
  font-size: 18px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 0
`;

export const TitleH3 = ({ size, className='', color='', children }) => {

    const personalizations = useSelector(selectAppPersonalizations);

    return (
        <DefaultH3 className={className} $color={personalizations?.h3?.color || color}>
            {children}
        </DefaultH3>
    )
}

