export const normalizeQuestions = (questions) => {
    let _papers = [];
    let counter = 1;
    let _questions = []
        questions.forEach(question => {
            if (question.question_type !== 'block') {
                _questions.push({
                    blocked_question: question.question && question.answers ? false : true,
                    ...question,
                    question_id: `${question.question_number}`,
                    virtual_question_number: counter
                });
                counter += 1;
            }
            if (question.question_type === 'block') {
                const blocks = question.blocks ?? [];
                console.log(question);
                blocks.forEach((block, idx) => {
                    const isFirstBlock = idx === 0;
                    const isLastBlock = idx === blocks?.length - 1;
                    const blockQuestion = { 
                    ...question,
                    ...block,
                    isFirstBlock,
                    isLastBlock,
                    isBlockQuestion: true,
                    blocked_question: block.question && block.answers ? false : true,
                    question_id: `${question.question_number}_${block.block_number}`,
                    virtual_question_number: counter
                    };
                    delete blockQuestion.blocks;
                    _questions.push(blockQuestion);
                    counter += 1;
                });
            }
            if(question.paper !== null && question.paper !== undefined && question.paper !== "") {
                _papers.push({
                    paper: question.paper,
                    question_number: question.question_number,
                    fontSize: 20,
                    selected_text: []
                })
            }
        });
    return {_questions, _papers};
};