import { useSelector } from "react-redux";
import { selectAppPersonalizations } from "../services/utils/appInfoSlice";
import styled from 'styled-components'
import { selectCurrentExamId } from "../features/Exam/examSlice";
import { API_URL } from "../config/constants";

const DefaultDiv = styled.div`
  /* Adapt the colors based on primary prop */
  background: ${props => props.$bg ? 
    (props.$bg.type === "colored" ? props.$bg.color : 
        (props.$bg.type === "image" ? "url("+API_URL+props.$bg.image_url+")" : "#fff")
    )
    : '#fff'} ;
`;

export const Div = ({className="", id="", children}) => {

    const personalizations = useSelector(selectAppPersonalizations);
    const currentExam = useSelector(selectCurrentExamId)

    return (
        <DefaultDiv $bg={currentExam ? '#FFF' : personalizations?.background} id={id} className={className}>
            {children}
        </DefaultDiv>
    )
}

