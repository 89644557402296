import { useEffect, useState } from 'react'

import {Button} from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserProfileData, selectUserData, selectUserInfo, updateUserData } from '../../features/User/userSlice';
import {TitleH2} from '../../components/TitleH2';
import { Avatar } from '../../components/_partials/Avatar';
import { API_URL } from '../../config/constants';

import edit_img from '../../assets/images/edit.svg'
import av_upload from '../../assets/images/avatar_upload.svg'
import img_upload from '../../assets/images/image.svg'
import data_img from './images/dati.svg'
import lock_img from './images/lock.svg'
import support_img from './images/assistenza.svg'
import arrow_img from '../../assets/images/arrow-back.svg'

import './AccountScreen.css'
import { selectTranslations } from '../../services/i18n/i18nSlice';
import {isMobile} from 'react-device-detect';

import Anchor from '../../components/Anchor';
import BottomSheet from '../../components/_modals/BottomSheet';
import AvatarSelectModule from './modules/AvatarSelectModule';
import PrivacyModule from './modules/PrivacyModule';
import UserDataModule from './modules/UserDataModule';
import ConfirmModal from '../../components/_modals/ConfirmModal';
import UploadButton from '../../components/_partials/Uploader';
import SecureImage from '../../components/_partials/SecuredImage';
import { logout } from '../../services/auth/authSlice';
import { setLoading, setNavbarHide, setNavbarHideMobile } from '../../services/global/globalSlice';
import { toast } from 'react-toastify';
import { TitleH1 } from '../../components/TitleH1';
import { selectAppInfo } from '../../services/utils/appInfoSlice';
import DesktopSheet from '../../components/_ofa_component/DesktopSheet';
import { isWebview } from '../../utils/utils';
import ContactModule from './modules/ContactModule';
import { useLocation } from 'react-router-dom';

export default function AccountScreen () {

    const [imageBottomSheetOpen, setImageBottomSheetOpen] = useState(false)
    const [activeModule, setActiveModule] = useState(null)
    const userInfo = useSelector(selectUserInfo)
    const userData = useSelector(selectUserData)
    const appInfo = useSelector(selectAppInfo)

    const location = useLocation()

    const t = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const [confirmModal, setConfirmModal] = useState({
        title: "",
        text: "",
        onClose: null,
        onConfirm: null,
        confirmCta: "",
        closeCta: ""
    })

    const handleActiveModule = (module) => {
        setActiveModule(module)
    }

    const closeConfirmModal = () => {
        setConfirmModal({
            ...confirmModal,
            show: false  
        })
    }

    const resetModule = () => {
        if(!userInfo?.is_saved){
            setConfirmModal({
                ...confirmModal,
                show: true,
                title: t.account.exit_without_save,
                text: t.account.exit_without_save_text,
                onClose: ()=>closeConfirmModal(),
                onConfirm: ()=>{dispatch(loadUserProfileData()); setActiveModule(null); closeConfirmModal();},
                confirmCta: t.modals.exit,
                closeCta: t.modals.back,
            })
        } else {
            setActiveModule(null)
        }
    }

    const handleLogoutCheck = () => {
        setConfirmModal({
            ...confirmModal,
            show: true,
            title: t.login.logout_title,
            text: t.login.logout_text,
            onClose: ()=>closeConfirmModal(),
            onConfirm: ()=>{dispatch(logout()); closeConfirmModal();},
            confirmCta: t.login.logout,
            closeCta: t.buttons.discard,
        })
    }

    const handleSaveProfileImage = async (e) => {
        let user_data = [
            {field_id: 'profile_image_url', field_values: e},
        ]
        dispatch(updateUserData({user_data: user_data})).then(data => {
            if (data.payload) {
                dispatch(loadUserProfileData())
                setImageBottomSheetOpen(false)
            } else {
                toast.error(t.errors.error_saving)
            }
        }) 
    }

    useEffect(()=>{
        if(isMobile && activeModule){
            dispatch(setNavbarHide(true))
        } else {
            dispatch(setNavbarHide(false))
        }
    }, [activeModule])

    useEffect(()=>{
        dispatch(setNavbarHideMobile(false))
        dispatch(setNavbarHide(false))
        dispatch(setLoading(false))
    },[])

    useEffect(()=>{
        if(location.state?.activeModule)
            setActiveModule(location.state?.activeModule)
    },[location])

    return(
        <div className='mt-0 mt-md-3 h-100'>
            <div id='userAccountMenu' hidden={activeModule !== null && isMobile}>
                <div>
                    <div id="userImage">
                        {userData?.profile_image_url ? 
                            <SecureImage imageUrl={`${API_URL}${userData?.profile_image_url}`} />
                        : <Avatar avatar_id={userData?.profile_avatar_id || 0} />
                        }
                        {!appInfo?.is_ofa &&
                            <img id="imageEdit" alt='' src={edit_img} onClick={()=>setImageBottomSheetOpen(true)}/>}
                    </div>
                    <TitleH2 className='text-center mt-3'>{userData?.firstname} {userData?.lastname}</TitleH2>
                    <div className='mt-4'>
                        <ul className='st_list'>
                            <li className={`mb-0 pointer ${activeModule === 'data' || (!isMobile && activeModule === null) ? 'active' : ''}`} onClick={()=>handleActiveModule("data")}>
                                <img src={data_img} alt='' />
                                <span>{t.account.personal_data}</span>
                            </li>
                            <hr className='divider my-2' />
                            <li className={`mb-0 pointer ${activeModule === 'privacy' ? 'active' : ''}`} onClick={()=>handleActiveModule("privacy")}>
                                <img src={lock_img} alt='' />
                                <span>{t.account.terms}</span>
                            </li>
                            <hr className='divider my-2'/>
                            <li className={`mb-0 pointer ${activeModule === 'contact' ? 'active' : ''}`} onClick={()=>handleActiveModule("contact")}>
                                <img src={support_img} alt='' />
                                <span>{t.account.contact}</span>
                            </li>
                            <hr className='divider my-2'/>
                        </ul>
                    </div>
                </div>
                {isMobile ? 
                <div className='mt-5'>
                    {(!isWebview()) &&<Button className='mx-auto w-100' color='secondary' onClick={handleLogoutCheck}>
                        {t.login.logout}
                    </Button>}
                </div>
                : <div className='mt-5 mb-4'>
                    <Anchor href='#' onClick={handleLogoutCheck}><u><strong>{t.login.logout}</strong></u></Anchor>
                </div>
                }
            </div>
            {activeModule !== null && isMobile ? 
                <div className='section_header'>
                    <img src={arrow_img} alt='' className='pointer' onClick={resetModule} />
                </div>
            : ""}
            <div id="userAccountModule" className={activeModule || (!isMobile && activeModule !== 'data') ? "active" : ""}>
                {!isMobile ?
                    <TitleH1>{userData?.firstname} {userData?.lastname}</TitleH1>
                : ''}
                { activeModule === "avatars" ?
                    <AvatarSelectModule />
                : ""}
                { activeModule === "privacy" ?
                    <PrivacyModule />
                : ""}
                { activeModule === "data" || (!isMobile && activeModule === null) ?
                    <UserDataModule />
                : ""}
                { activeModule === "contact" ?
                    <ContactModule />
                : ""}
            </div>
            <BottomSheet isOpen={imageBottomSheetOpen} onClose={()=>setImageBottomSheetOpen(false)}>
                <div className='mb-5 mb-md-4 mt-3 px-4'>
                    <ul className='st_list'>
                        <li>
                            <UploadButton
                                type='profile_image'
                                accept='image/*'
                                onUpload={handleSaveProfileImage}
                            >
                                <img src={img_upload} alt='' />
                                <span>{t.account.upload_image}</span>
                            </UploadButton>
                        </li>
                        <li className='pointer' onClick={()=>{setImageBottomSheetOpen(false);handleActiveModule("avatars")}}>
                            <img src={av_upload} alt='' />
                            <span>{t.account.choose_avatar}</span>
                        </li>
                    </ul>
                </div>
            </BottomSheet>
            <ConfirmModal props={confirmModal} />
            {
                appInfo.is_ofa && 
                    <DesktopSheet />
            }
        </div>
    )
}