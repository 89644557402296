import { useDispatch, useSelector } from 'react-redux';
import { selectAppAuth, setOriginLocation } from '../../services/auth/authSlice';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { checkUserFields, selectUserInfo } from '../User/userSlice';
import LoaderScreen from '../../screens/RootScreen/LoaderScreen';

export const ProtectedRoute = ({
  redirectPath = '/login',
  waitingPath = '/login/waiting_approval',
  onboardingPath = '/login/onboarding',
  skipCheck = '',
  children,
}) => {
    const userAuth = useSelector(selectAppAuth)
    const userInfo = useSelector(selectUserInfo)
    const missingFields = useSelector(checkUserFields)
    const location = useLocation()
    const dispatch = useDispatch()

  
    useEffect(()=>{
      dispatch(setOriginLocation(location.pathname))
      // eslint-disable-next-line
    },[children])

    if ((!userAuth.auth_token && userAuth.refresh_auth) || (userAuth.auth_token && (!userInfo.user_data || !userInfo.user_fields))) {
      return <LoaderScreen />;
    } else if (!userAuth.auth_token) {
      return <Navigate to={redirectPath} replace />;
    } else if (userInfo?.user_data && userAuth.auth_token && ( userAuth.admin_check_required || userInfo?.user_data?.state === "waiting_admin_check") && skipCheck !== 'approval'){
      return <Navigate to={waitingPath} replace />;
    }else if (userInfo?.user_data && userInfo?.user_fields && (missingFields || !userInfo?.user_data?.legal_checks?.external_legal_check) && userInfo?.is_saved && ( !userAuth.admin_check_required && userInfo?.user_data?.state !== "waiting_admin_check") && skipCheck !== 'data'){
      return <Navigate to={onboardingPath} replace />;
    }else if(userAuth && userInfo?.user_data)
      return children ? children : <Outlet />;
};