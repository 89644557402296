import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { TitleH3 } from '../../../components/TitleH3'
import { useNavigate } from 'react-router-dom'

import img_arrow from '../../../assets/images/right.svg'
import training_img from '../../../assets/images/folders/training.svg'
import folder_img from '../../../assets/images/folders/folder.svg'
import lesson_img from '../../../assets/images/folders/lesson.svg'
import correct_img from '../../../assets/images/folders/correct.svg'
import folder_bg from '../../../assets/images/folders/bg.png'
import incomplete_img from '../../../assets/images/ofa/incomplete_folder.svg'
 
import { useSelector } from 'react-redux'
import { selectTranslations } from '../../../services/i18n/i18nSlice'
import { useEffect, useState } from 'react'
import { fetchGetSubFolders } from '../FolderApi'
import { toast } from 'react-toastify'
import SecureImage from '../../../components/_partials/SecuredImage'

import { API_URL } from '../../../config/constants'
import { selectAppInfo } from '../../../services/utils/appInfoSlice'
import { ProgressBar } from 'react-bootstrap'
import StartTrainingModal from './StartTrainingModal'


export default function Folder ({folder, hasContent}) {

    const t = useSelector(selectTranslations)
    const [subFolders, setSubfolders] = useState([])
    const [showTrainingModal, setShowTrainingModal] = useState(false)
    const navigate = useNavigate()

    const appInfo = useSelector(selectAppInfo)

    const openFolder = () =>{
        if(folder?.videos?.legth > 0 || folder?.files?.length > 0 || folder.links?.length > 0)
            navigate(`/folder/${folder.folder_id}`)
        else if(folder.training)
            setShowTrainingModal(true)
        else
            navigate(`/folder/${folder.folder_id}`)
    }

    const loadSubFolders = async () => {
        const response = await fetchGetSubFolders({parent_folder_id: folder?.folder_id})
        if (response.success){
            setSubfolders(response?.data?.folders || [])
        } else {
            toast.error(t.training.error_loading_folder)
        }
    } 

    const getTotalSubfoldersVideo = () => {
        let _count = 0;
        subFolders?.forEach(s => {
            _count = _count + s?.videos?.length
        })
        return _count
    }

    const getTotalSubfoldersVideoViewed = () => {
        let _count = 0;
        subFolders?.forEach(s => {
            _count = _count + s?.videos?.filter(v=>v.completed).length
        })
        return _count
    }

    useEffect(( )=>{
        if(folder && folder.folder_id)
            loadSubFolders()
    },[folder])


    const content = ( ) => {
        let _count = 0
        _count = _count + (folder?.videos?.length || 0)
        _count = _count + (folder?.files?.length || 0)
        _count = _count + (folder?.links?.length || 0)
        return _count
    }

    const closeTrainingModal = () => {
        setShowTrainingModal(false)
    }

    return(
        <>
        <div className={`folder pointer ${hasContent ? 'rounded' : '' }`} onClick={openFolder}>
            {!hasContent ?
                <div className='folder__content'>
                    {
                        folder ? 
                        <>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    {folder?.icon_url && <SecureImage height='20px' width='20px' className='folder_icon me-2' imageUrl={`${API_URL}${folder.icon_url}`} />}
                                    <TitleH3> {folder?.name}</TitleH3>
                                </div>
                                <img src={img_arrow} className='pointer' alt="" onClick={openFolder}/>
                            </div>
                            <div className='mt-3'>
                                <div className='d-flex'>
                                    {folder.training ?
                                    <span className='d-block me-2'>
                                        <img className='me-1' src={training_img} style={{verticalAlign:'sub'}}/>
                                        <span><strong>1</strong> {t.training.training}</span>
                                    </span> : null }
                                    {subFolders.length !== 0 ?
                                    <span className='d-block me-2'>
                                        <img className='me-1' src={folder_img} style={{verticalAlign:'sub'}}/>
                                        <span><strong>{subFolders.length}</strong> {!appInfo?.is_ofa ? t.training.folders: t.training.units}</span>
                                    </span> : null}
                                    {content() > 0 ?
                                    <span className='d-block '>
                                        <img className='me-1' src={lesson_img} style={{verticalAlign:'sub'}} />
                                        <span><strong>{content()}</strong> {t.training.materials}</span>
                                    </span> : null}
                                </div>
                                {
                                    (appInfo.is_ofa && subFolders?.length > 0) &&
                                        <div className='d-flex align-items-center mt-2'>
                                            <span className='me-2 mb-0'>{parseInt(getTotalSubfoldersVideoViewed()*100/getTotalSubfoldersVideo())}%</span>
                                            <ProgressBar className='' style={{height:'6px',width:'80%'}} now={getTotalSubfoldersVideoViewed()} max={getTotalSubfoldersVideo()}/>
                                        </div>
                                }
                            </div>
                        </> : <>
                            <Skeleton count={2}/>
                        </>
                    }
                </div> :
            <div className='folder__content'>
                {
                    folder ? 
                    <>
                        <div className='folder__image'>
                            <SecureImage className='folder_icon' height='50px' width='50px' imageUrl={`${folder?.icon_url ? `${API_URL}${folder?.icon_url}` : folder_bg }`} />
                        </div>
                        { (folder.training || subFolders?.length !== 0 || content() > 0) &&
                            <div className='folder__details'>
                                {folder.training &&
                                <span>
                                    <img className='me-1' src={training_img} style={{verticalAlign:'sub'}}/>
                                </span>}
                                {subFolders.length !== 0 &&
                                <span >
                                    <img className='me-1' src={folder_img} style={{verticalAlign:'sub'}}/>
                                </span>}
                                {content() > 0 &&
                                <span >
                                    <img className='me-1' src={lesson_img} style={{verticalAlign:'sub'}} />
                                </span>}
                            </div>
                        }
                        <div className='folder__title'>
                            <TitleH3>{folder?.name}
                            {(appInfo.is_ofa && folder?.videos?.length > 0) &&  
                                <img src={folder?.videos?.find(v => !v.completed) ? incomplete_img : correct_img} className='ms-2' alt="" />}
                            </TitleH3>
                        </div>
                    </> : <>
                        <Skeleton count={2}/>
                    </>
                }
            </div>
            }
        </div>
        {   folder &&
            <>
                {
                    (folder?.training && folder?.training?.ready) &&
                        <StartTrainingModal folder={folder} show={showTrainingModal} onClose={closeTrainingModal} />
                }
            </>
        }
        </>
    )
}