import React, {useEffect, useState, useContext, useRef} from "react"
import { ProgressBar } from "react-bootstrap";
import { useTimer } from 'react-timer-hook';

import Lottie from 'lottie-react';
import timerAnimation from '../assets/timer.json'
import arrow_left from '../assets/images/arrow_previous.svg'
import arrow_right from '../assets/images/arrow_next.svg'

import "./Navigation.css";
import { Button } from "../../../components/Button";
import { fetchUpdateConsumedTime } from "../ExamsApi";
import { selectCurrentExamId } from "../../../features/Exam/examSlice";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";

export default function Navigation (props) {

    const expiryTime = new Date((new Date).getTime() + 60*60000)
    const exam_id = useSelector(selectCurrentExamId)

    const t = useSelector(selectTranslations)

    const [timeoutInterval, setTimeoutInterval ] = useState(null);
    
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({expiryTimestamp:expiryTime, onExpire: () => handleExpiration() });

    const seconds_ref = useRef(seconds);
    const minutes_ref = useRef(minutes);
    const hours_ref = useRef(hours);

    const handleExpiration = () => {
        console.log(expiryTime);
        if(props.navigationData?.isLinearModuleNavigation && props?.userModule?.module_number+1 === props.navigationData?.modulesCount)
            props.handleEndModule()
        else    
            props.handleEndExam()
    }

    const handleUpdateTime = async () => {
        const new_consumed_time = parseInt(props.timeout) - parseInt(hours_ref.current*60 + minutes_ref.current + parseInt(seconds_ref.current/60));
        //TODO: remoteLog(userAuthToken, 'info', `updated consumed time to ${new_consumed_time} minutes`)
        let response = await fetchUpdateConsumedTime({exam_id: exam_id, consumed_time: new_consumed_time});
        if (!response.success){
            console.warn(response.error)
        }
    }

    const changeQuestion = (index, module_number) => {
        if(!props.navigationData.is_linear_question_navigation)
            if(!props?.navigationData?.is_linear_module_navigation || (props?.navigationData?.is_linear_module_navigation && module_number === props?.navigationData?.current_module_number))
                props.handleChangeQuestion(index);
    }

    const countMissing = () => {
        var count_missing = 0;
        props?.questions?.forEach((question) => {
            if ((props?.navigationData?.is_linear_module_navigation && props?.navigationData?.current_module_number === question.module_number) || !props?.navigationData?.is_linear_module_navigation)    
                count_missing = question.selected_answer === null || isNaN(question.selected_answer) ? count_missing + 1 : count_missing;
        })
        return count_missing;
    }

    const askEndExam = () => {
        props?.handleCloseExam()
    }

    const askEndModule = () => {
        props?.handleCloseModule()
    }

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }
    
    const renderColor = () => {
        let perc = ((hours_ref.current * 3600 + minutes_ref.current * 60 + (seconds_ref.current))*100)/(props.timeout);
        if (perc > 40)
            return ""
        else if (perc > 20)
            return "warning"
        else
            return "danger"
    }

    useEffect(() => {
        seconds_ref.current = seconds;
        minutes_ref.current = minutes;
        hours_ref.current = hours;
    }, [seconds])

    useEffect(()=>{
        if(props.timeout && !timeoutInterval){
            let time = new Date();
            time.setSeconds(time.getSeconds() + props.timeout*60 - props.consumed_time*60); 
            restart(time);
            let id_interval = setInterval(handleUpdateTime, 60000);
            props.saveGlobalTimeout(time, id_interval)
            setTimeoutInterval(id_interval)
        }
    },[props.timeout])

    return (
        <div className="col-3 navigation_bar pb-5">
            <div className="d-flex align-items-top justify-content-start">
                <Lottie
                    animationData={timerAnimation}
                    autoplay={true}
                    loop={100000}
                    style={{width:"35px"}}
                    className="d-block me-2" 
                />
                <div className={"exam_timer " + "text-"+renderColor()}>
                    <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
                </div>
            </div>
            <ProgressBar className="time_pbar" now={((hours_ref.current * 3600 + minutes_ref.current * 60 + (seconds_ref.current))*100)/(props.timeout*60)} />
            
            {props?.navigationData?.isLinearModuleNavigation ? <>
                {props.userModules[props?.navigationData?.currentModule].module_number +1 !== props.navigationData?.modulesCount ? 
                <Button className="mt-5 mb-4" onClick={askEndModule}>{t.exam.end_module} {props.userModules[props?.navigationData?.currentModule].module_number +1} </Button>
                : <Button className="mt-5 mb-4" onClick={askEndExam}>{t.exam.end_exam}</Button>}
            </> : <>
                <Button className="mt-5 mb-4" onClick={askEndExam}>{t.exam.end_exam}</Button>
            </>}
            {
                props.userModules[props?.navigationData?.currentModule]?.module_name ? 
                    <div className="mb-0">
                        <p className="text-black mb-0" style={{fontWeight: '800'}}>Modulo {props.userModules[props?.navigationData?.currentModule].module_number +1}: {props.userModules[props?.navigationData?.currentModule].module_name} </p>
                    </div>
                : ""
            }
            <div className="">
                <p className="text-secondary" style={{fontSize:'14px'}}> Risposte mancanti: {props.remainingQuestions} </p>
            </div>
            {
                !props.navigationData.isLinearModuleNavigation ?
                    <div className="mt-3">
                        {props?.userModules?.length > 1 &&
                        <div className="d-flex w-100 justify-content-between">
                                <div className="d-flex pointer" onClick={()=>{props.handleChangeModule(props.navigationData.currentModule-1)}} style={{visibility: props.navigationData.currentModule != 0 ? 'visible' : 'hidden'}}>
                                    <div className="arrow_navigation me-1"><img src={arrow_left} /> </div>
                                    <span>{t.exam.module} {props.navigationData.currentModule}</span>
                                </div>
                                <div className="d-flex pointer" onClick={()=>{props.handleChangeModule(props.navigationData.currentModule+1)}} style={{visibility: props.navigationData.currentModule+1 < props.userModules?.length ? 'visible' : 'hidden'}}>
                                    <span>{t.exam.module} {props.navigationData.currentModule+2}</span>
                                    <div className="arrow_navigation ms-1"><img src={arrow_right} /> </div>
                                </div>
                        </div>}
                        <div className="mt-3 questions_nav">
                            {
                                props?.questions?.map((question, index) => {
                                    if(question.module_number === props.navigationData.currentModule){
                                        let has_answer = ((question.selected_answer_number !== null && question.selected_answer_number !== undefined && !isNaN(question.selected_answer_number)) || (question.selected_answer_numbers !== null && question.selected_answer_numbers !== undefined && question?.selected_answer_numbers?.length !== 0 )) ? true : false;
                                        return (
                                            <div key={index} className={"question_el_external " + (question.isBlockQuestion ? "block_wrapped " : "") + (question.isFirstBlock ? "block_wrapped_first " : "") + (question.isLastBlock ? "block_wrapped_last " : "") }>
                                                <div key={index} onClick={() => {changeQuestion(question.question_id, question.module_number)}} className={"question_el " + (has_answer ? "answered " : "") + (question.is_uncertain ? "is_uncertain " : "" ) + (question.question_id === props.navigationData.currentQuestion ? "current " : "") + (props.navigationData.is_linear_module_navigation && (props.navigationData?.current_module_number !== question.module_number) ? "disabled_question " : "")}>
                                                    {parseInt(index)+1}
                                                </div>
                                            </div>
                                        )
                                    } else return null
                                })
                            }
                        </div>
                    </div>
                : <div className="mt-4 questions_nav">
                    {
                        props?.questions?.map((question, index) => {
                            let has_answer = ((question.selected_answer_number !== null && question.selected_answer_number !== undefined && !isNaN(question.selected_answer_number)) || (question.selected_answer_numbers !== null && question.selected_answer_numbers !== undefined && question?.selected_answer_numbers?.length !== 0 )) ? true : false;
                            return (
                                <div key={index} className={"question_el_external " + (question.isBlockQuestion ? "block_wrapped " : "") + (question.isFirstBlock ? "block_wrapped_first " : "") + (question.isLastBlock ? "block_wrapped_last " : "") }>
                                    <div key={index} onClick={() => {changeQuestion(question.question_id, question.module_number)}} className={"question_el " + (has_answer ? "answered " : "") + (question.is_uncertain ? "is_uncertain " : "" ) + (question.question_id === props.navigationData.currentQuestion ? "current " : "") + (props.navigationData.is_linear_module_navigation && (props.navigationData?.current_module_number !== question.module_number) ? "disabled_question " : "")}>
                                        {parseInt(index)+1}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}