import CallServer from '../../api/CallServerV2';

export async function fetchStartTraining (params = {}) {
    return await CallServer({
        endpoint: '/user/start_training',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 

export async function fetchRespondToTrainingQuestion (params = {},answer_data) {
    return await CallServer({
        endpoint: '/user/respond_to_training_question',
        params: params,
        needAuthenticate: true,
        body: answer_data,
        retryIfFailed: true
    })
} 