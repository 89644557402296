import { useDispatch, useSelector } from "react-redux"
import { selectUserInfo } from "../../../features/User/userSlice"
import { selectTranslations } from "../../../services/i18n/i18nSlice"

import mail_img from './../images/mail.svg' 
import wh_img from './../images/whatsapp.svg' 
import right_img from '../../../assets/images/right.svg'
import support_img from '../images/assistenza.svg'

import { TitleH2 } from "../../../components/TitleH2"
import { Text } from "../../../components/Text"

export default function ContactModule (props) {

    const t = useSelector(selectTranslations)

    return (
        <div id="contactModule">
            <div className="mt-0 mt-md-4 page_title">
                <img src={support_img} />
                <TitleH2 className="mb-0">{t.account.contact}</TitleH2>
            </div>
            <Text className="mt-2">{t.account.contact_text}</Text>
            <div className="contact_cards mt-3 mb-3">
                <a href="https://wa.me/393792045980" target="_blank" rel="noreferrer">
                    <div className="contact_card">
                        <img src={wh_img} alt="" />
                        <div>
                            <TitleH2>WhatsApp</TitleH2>
                            <Text>Per tempi di risposta più brevi</Text>
                        </div>
                        <img src={right_img} alt="" />
                    </div>
                </a>
                <a href="mailto:assistenza@thefaculty.it" target="_blank" rel="noreferrer">
                    <div className="contact_card">
                        <img src={mail_img} alt="" />
                        <div>
                            <TitleH2>E-mail</TitleH2>
                            <Text>Risposta stimata: massimo 24h</Text>
                        </div>
                        <img src={right_img} alt="" />
                    </div>
                </a>
            </div>
            <span className='pt-5 mt-5'>
                App version: {process.env.REACT_APP_VERSION}
            </span>
        </div>
    )
}