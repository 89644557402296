import Spinner from "react-bootstrap/Spinner";
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    jsutify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #37373780;
    z-index: 100000;
`;


export default function MainLoader () {
    return (
        <Container id="pageLoader" >
            <Spinner className="mx-auto" style={{width:'4rem', height:'4rem', color:"var(--primary-color)"}}/>
        </Container>
    )
}