import {store} from '../app/store'
import { selectApiUrl, selectAppPersonalizations } from '../services/utils/appInfoSlice';

async function loadFontFace(fontFace) {
    try{
        const loadedFont = await fontFace.load();
        document.fonts.add(loadedFont);
    } catch (e) {
        console.error(e)
    }
    
}

export function loadGlobalFonts () {

    const personalizations = selectAppPersonalizations(store.getState());
    const API_URL = selectApiUrl(store.getState());
    const availableTypes = ['text', 'link', 'h1', 'h2', 'h3', 'primary_button', 'secondary_button']

    let fonts = []
    if(personalizations){
        availableTypes.forEach((type)=>{
            if(personalizations[type] && personalizations[type]?.font_url){
                fonts.push({
                    font_url: `${API_URL}${personalizations[type]?.font_url}`,
                    type: `font_${type}`,
                    weight: type !== 'text' ? 700 : 500
                })
            } else if (personalizations[type] && personalizations[type]?.text?.font_url) {
                fonts.push({
                    font_url: `${API_URL}${personalizations[type]?.text?.font_url}`,
                    type: `font_${type}`,
                    weight: type !== 'text' ? 700 : 500
                })
            }
        })
        const fontFaces = fonts.map((font) => {
            return new FontFace(font.type, `url(${font.font_url})`)
        })
        fontFaces.forEach((fontFace) => {
            loadFontFace(fontFace);
        });
    }

}