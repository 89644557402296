import React, {useEffect, useState, useContext, useRef} from "react"
import { useTimer } from 'react-timer-hook';

import Lottie from 'lottie-react';
import timerAnimation from '../assets/timer.json'

import "./Navigation.css";
import { fetchUpdateConsumedTime } from "../ExamsApi";
import { selectCurrentExamId } from "../../../features/Exam/examSlice";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";

export default function TimerMobile (props) {

    const expiryTime = new Date((new Date).getTime() + 60*60000)

    const exam_id = useSelector(selectCurrentExamId)

    const t = useSelector(selectTranslations)

    const [timeoutInterval, setTimeoutInterval ] = useState(null);
    
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({expiryTimestamp:expiryTime, onExpire: () => handleExpiration() });

    const seconds_ref = useRef(seconds);
    const minutes_ref = useRef(minutes);
    const hours_ref = useRef(hours);

    const handleExpiration = () => {
        if(props.navigationData?.isLinearModuleNavigation && props?.userModule?.module_number+1 === props.navigationData?.modulesCount)
            props.handleEndModule()
        else    
            props.handleEndExam()
    }

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }
    
    const renderColor = () => {
        let perc = ((hours_ref.current * 3600 + minutes_ref.current * 60 + (seconds_ref.current))*100)/(props.timeout);
        if (perc > 40)
            return ""
        else if (perc > 20)
            return "warning"
        else
            return "danger"
    }

    const handleUpdateTime = async () => {
        const new_consumed_time = parseInt(props.timeout) - parseInt(hours_ref.current*60 + minutes_ref.current + parseInt(seconds_ref.current/60));
        //TODO: remoteLog(userAuthToken, 'info', `updated consumed time to ${new_consumed_time} minutes`)
        const response = await fetchUpdateConsumedTime({exam_id: exam_id, consumed_time: new_consumed_time});
        if (!response.success){
            console.warn(response.error)
        }
    }

    useEffect(() => {
        seconds_ref.current = seconds;
        minutes_ref.current = minutes;
        hours_ref.current = hours;
    }, [seconds])

    useEffect(()=>{
        if(props.timeout){
            let time = new Date();
            time.setSeconds(time.getSeconds() + props.timeout*60 - props.consumed_time*60); 
            restart(time);
            let id_interval = setInterval(handleUpdateTime, 60000);
            props.saveGlobalTimeout(time, id_interval)
            setTimeoutInterval(id_interval)
        }
    },[props.timeout])

    return (
        <div className="col-4">
            <div className="d-flex align-items-center justify-content-start">
                <Lottie
                    animationData={timerAnimation}
                    autoplay={true}
                    loop={100000}
                    style={{width:"25px"}}
                    className="d-block me-2" 
                />
                <div className={"exam_timer " + "text-"+renderColor()}>
                    <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
                </div>
            </div>
        </div>
    )
}