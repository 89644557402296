import React, {useEffect, useRef, useState} from "react"


import "./Navigation.css";
import { Button } from "../../../components/Button";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import Sheet from 'react-modal-sheet';

import up_img from '../../../assets/images/questions/controller_down.svg'


export default function NavigationMobile(props) {

    const t = useSelector(selectTranslations)

    const ref = useRef()

    const changeQuestion = (index, module_number) => {
        if(!props.navigationData.is_linear_question_navigation)
            if(!props?.navigationData?.is_linear_module_navigation || (props?.navigationData?.is_linear_module_navigation && module_number === props?.navigationData?.current_module_number))
                props.handleChangeQuestion(index);
    }

    const askEndExam = () => {
        props.setOpen(false)
        props?.handleCloseExam()
    }

    const askEndModule = () => {
        props.setOpen(false)
        props?.handleCloseModule()
    }

    useEffect(()=>{
        if(props.open && ref?.current)
            setTimeout(()=>{
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                });
            },300)
    },[props.open])

    return (
            <Sheet isOpen={props.open} onClose={()=>props.setOpen(false)} snapPoints={[400]} detent='full-height'>
                
                <Sheet.Container>
                    <div className=" d-flex pt-2 justify-content-center" onClick={()=>props.setOpen(false)}>
                        <img src={up_img} alt="" />
                    </div>
                    <Sheet.Content> 
                        <Sheet.Scroller>
                            <div className="px-4">
                                
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="text-secondary mb-0" style={{fontSize:'14px'}}> Risposte mancanti: {props.remainingQuestions} </p>
                                    {props?.navigationData?.isLinearModuleNavigation ? <>
                                        {props.userModules[props?.navigationData?.currentModule]?.module_number +1 !== props.navigationData?.modulesCount ? 
                                        <Button  onClick={askEndModule}>{t.exam.generic_end} </Button>
                                        : <Button  onClick={askEndExam}>{t.exam.generic_end}</Button>}
                                    </> : <>
                                        <Button  onClick={askEndExam}>{t.exam.generic_end}</Button>
                                    </>}
                                </div>
                                {
                                    !props.navigationData.isLinearModuleNavigation ?
                                        <div className="mt-3 mb-5">
                                            { props?.userModules?.map((module, index)=>{
                                                return (
                                                <div key={index} className="mb-2" ref={props.navigationData.currentModule === index ? ref : null}>
                                                {
                                                    module?.module_name ? 
                                                        <div className="mb-0">
                                                            <p className="text-black mb-0" style={{fontWeight: '800'}}>Modulo {module.module_number +1}: {module.module_name} </p>
                                                        </div>
                                                    : ""
                                                }
                                                <div className="questions_nav sheet_navigation">
                                                    {
                                                        props?.questions?.map((question, index) => {
                                                            if(question.module_number === module.module_number){
                                                                let has_answer = ((question.selected_answer_number !== null && question.selected_answer_number !== undefined && !isNaN(question.selected_answer_number)) || (question.selected_answer_numbers !== null && question.selected_answer_numbers !== undefined && question?.selected_answer_numbers?.length !== 0 )) ? true : false;
                                                                return (
                                                                    <div key={index} className={"question_el_external " + (question.isBlockQuestion ? "block_wrapped " : "") + (question.isFirstBlock ? "block_wrapped_first " : "") + (question.isLastBlock ? "block_wrapped_last " : "") }>
                                                                        <div key={index} onClick={() => {changeQuestion(question.question_id, question.module_number)}} className={"question_el " + (has_answer ? "answered " : "") + (question.is_uncertain ? "is_uncertain " : "" ) + (question.question_id === props.navigationData.currentQuestion ? "current " : "") + (props.navigationData.is_linear_module_navigation && (props.navigationData?.current_module_number !== question.module_number) ? "disabled_question " : "")}>
                                                                            {parseInt(index)+1}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else return null
                                                        })
                                                    }
                                                </div>
                                                </div>)
                                            })}
                                        </div>
                                    : <div className="mt-4 mb-5">
                                        {
                                            props.userModules[props?.navigationData?.currentModule]?.module_name ? 
                                                <div className="mb-0">
                                                    <p className="text-black mb-0" style={{fontWeight: '800'}}>Modulo {props.userModules[props?.navigationData?.currentModule].module_number +1}: {props.userModules[props?.navigationData?.currentModule].module_name} </p>
                                                </div>
                                            : ""
                                        }
                                        <div className="questions_nav sheet_navigation">
                                            {
                                                props?.questions?.map((question, index) => {
                                                    let has_answer = question.selected_answer !== null && !isNaN(question.selected_answer) ? true : false;
                                                    return (
                                                        <div key={index} className={"question_el_external " + (question.isBlockQuestion ? "block_wrapped " : "") + (question.isFirstBlock ? "block_wrapped_first " : "") + (question.isLastBlock ? "block_wrapped_last " : "") }>
                                                            <div key={index} onClick={() => {changeQuestion(question.question_id, question.module_number)}} className={"question_el " + (has_answer ? "answered " : "") + (question.is_uncertain ? "is_uncertain " : "" ) + (question.question_id === props.navigationData.currentQuestion ? "current " : "") + (props.navigationData.is_linear_module_navigation && (props.navigationData?.current_module_number !== question.module_number) ? "disabled_question " : "")}>
                                                                {parseInt(index)+1}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        
                                    </div>
                                }
                            </div>
                    </Sheet.Scroller>
                    </Sheet.Content>
                </Sheet.Container>
               <Sheet.Backdrop onTap={()=>props.setOpen(false)}/>
            </Sheet>
    )
}