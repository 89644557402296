import { useDispatch, useSelector } from "react-redux"
import { selectUserData, selectUserFields, updateUserData, updateUserField } from "../../../features/User/userSlice"
import { selectCurrentLang, selectTranslations } from "../../../services/i18n/i18nSlice"
import {TitleH2} from "../../../components/TitleH2"

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import data_img from '../images/dati.svg'
import info_img from '../../../assets/images/info.svg'
import computer_img from '../../../assets/images/computer-pc.svg'

import {Button} from "../../../components/Button";
import Form from 'react-bootstrap/Form';
import { useState } from "react"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import { DEFAULT_FIELDS } from "../../../config/constants"
import { SelectOption } from "../../../components/_forms/CustomSelectOption"
import Select from 'react-select'
import { toast } from 'react-toastify';
import { CustomSelectValueContainer } from "../../../components/_forms/CustomSelectValueContainer"
import { isMobile } from "react-device-detect"
import { selectAppInfo } from "../../../services/utils/appInfoSlice"
import { isWebview, openTfApp } from "../../../utils/utils";
import { Text } from "../../../components/Text";
import Anchor from "../../../components/Anchor";

export default function UserDataModule (props) {

    const userData = useSelector(selectUserData)
    const userFields = useSelector(selectUserFields)
    const t = useSelector(selectTranslations)
    const currentLang = useSelector(selectCurrentLang)
    const dispatch = useDispatch()

    const appInfo = useSelector(selectAppInfo)

    const [validatedForm, setValidatedForm] = useState(false);
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        setValidatedForm(true);
        console.log(form.checkValidity());
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            
            let user_data = []
            let field_id = null
            userFields?.forEach((f)=>{
                field_id = f.field_id
                if(DEFAULT_FIELDS.includes(field_id))
                    user_data.push({field_id: field_id, field_values:userData[field_id] || ""})
                else{
                    const field_value = userData?.extra_fields.find(e => e.field_id === field_id)?.values
                    if(field_value)
                        user_data.push({field_id: field_id, field_values:f.field_type === "datetime" ? `${new Date(field_value).toISOString().substring(0,19)}+00:00` : field_value})
                } 
            })
            dispatch(updateUserData({user_data: user_data})).then(data => {
                if (data.payload) {
                    toast.success(t.account.data_updated)
                } else {
                    toast.error(t.errors.error_saving)
                }
            })  
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom_tooltip" {...props}>
            <Text className="mb-0">{t.account.ofa_data}</Text>
        </Tooltip>
    );


    return (
        <div id="userDataModule">
            <div className="mt-0 mt-md-4 d-flex align-items-center justify-content-between">
                <div className="page_title">
                    <img src={data_img} alt="" />
                    <TitleH2 className="mb-0">{t.account.personal_data}</TitleH2>
                </div>
                {
                    appInfo.is_ofa &&
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                            >
                            <img src={info_img} alt="" />
                        </OverlayTrigger>
                }
            </div>
            <Form noValidate validated={validatedForm} onSubmit={handleSubmit} className="mt-4">
                <Form.Group controlId={'email'} className="mb-3">
                    <FloatingLabel
                        controlId={'email'}
                        label={<>{t.account.login_email} <img src={computer_img} alt="" /></>}
                    >
                        <Form.Control disabled readOnly type={'email'} placeholder={'E-mail'} value={userData['email'] || ''}/>
                    </FloatingLabel>
                    
                </Form.Group>
                {
                    userFields?.map((f)=>{
                        const field_id = f.field_id
                        if(DEFAULT_FIELDS.includes(field_id)){
                            return(
                                <Form.Group controlId={field_id} key={field_id} className="mb-3">
                                    <FloatingLabel
                                        controlId={field_id}
                                        label={f.field_names[currentLang]}
                                        key={field_id}
                                    >
                                        <Form.Control disabled={appInfo?.is_ofa} required={f.field_level < 2} type={f.field_type} placeholder={f.field_names[currentLang]} value={userData[field_id] || ''} onChange={(e)=>dispatch(updateUserField({value: e.target.value, field_id: field_id, type:'default'}))}/>
                                        <Form.Control.Feedback  type="invalid">
                                        <small className="text-danger">{t.forms.invalid_input}</small>
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    
                                </Form.Group>
                            )
                        } else {
                            return(
                                <Form.Group controlId={field_id} key={field_id} className="mb-3">
                                    
                                        {
                                            f.field_type === 'datetime'?
                                            <FloatingLabel
                                                controlId={field_id}
                                                label={f.field_names[currentLang]}
                                                key={field_id}
                                            >
                                                <Form.Control disabled={appInfo?.is_ofa} required={f.field_level < 2} type={'date'} value={userData?.extra_fields.find(e => e.field_id === field_id)?.values?.substring(0,10) || ''}  onChange={(e)=>dispatch(updateUserField({value: e.target.value, field_id: field_id, type:'extra'}))}/>
                                                <Form.Control.Feedback  type="invalid">
                                                    <small className="text-danger">{t.forms.invalid_input}</small>
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            : ""
                                        }
                                        {
                                            f.field_type === 'text'?
                                                <FloatingLabel
                                                    controlId={field_id}
                                                    label={f.field_names[currentLang]}
                                                    key={field_id}
                                                >
                                                    <Form.Control disabled={appInfo?.is_ofa}  required={f.field_level < 2}  type={f.field_type} placeholder={f.field_names[currentLang]} value={userData?.extra_fields.find(e => e.field_id === field_id)?.values || ''} onChange={(e)=>dispatch(updateUserField({value: e.target.value, field_id: field_id, type:'extra'}))}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        <small className="text-danger">{t.forms.invalid_input}</small>
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            : ""
                                        }
                                        {
                                            f.field_type === 'singular' ||  f.field_type === "multiple"?
                                                <>
                                                    <Select
                                                        options={f.choices?.map((c)=>{return({ value: c, label: c })})}
                                                        isMulti={f.field_type === "multiple"}
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        classNamePrefix="react-select"
                                                        required={f.field_level < 2} 
                                                        components={{
                                                            Option: SelectOption,
                                                            ValueContainer: CustomSelectValueContainer
                                                        }}
                                                        disabled={appInfo?.is_ofa}
                                                        className="select-form-control"
                                                        allowSelectAll={true}
                                                        styles={{
                                                            placeholder: (provided, state) => ({
                                                                ...provided,
                                                                position: "absolute",
                                                                top: state.hasValue || state.selectProps.inputValue ? -15 : "15%",
                                                                transition: "top 0.1s, font-size 0.1s",
                                                                fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                                            })
                                                        }}
                                                        placeholder={<div>{f.field_names[currentLang]}</div>}
                                                        value={
                                                            f.field_type === 'singular' ?
                                                                (userData?.extra_fields.find(e => e.field_id === field_id)?.values[0]) ? { value: userData?.extra_fields.find(e => e.field_id === field_id)?.values[0], label: userData?.extra_fields.find(e => e.field_id === field_id)?.values[0] } : ''
                                                            : userData?.extra_fields.find(e => e.field_id === field_id)?.values?.map((c)=>{return({ value: c, label: c })}) || ''
                                                        }
                                                        onChange={(e)=>dispatch(updateUserField({value: e, field_id: field_id, type:'extra', field_type:'choices'}))}
                                                    />
                                                    {f.field_level === 0 && ! userData?.extra_fields.find(e => e.field_id === field_id)?.values?.length > 0 ?
                                                        <small className="text-danger">{t.forms.invalid_select}</small>
                                                    : null}
                                                </>
                                            : ""
                                        }
                                    <Form.Control.Feedback type="invalid">
                                        <small className="text-danger">{t.forms.invalid_select}</small>
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                            )
                        } 
                    })
                }
                { !appInfo?.is_ofa ?
                    <>{
                            isMobile ?
                            <div className="bottom_controller d-flex justify-content-center">
                                <Button className="mt-2 mb-5 w-100 mx-auto mx-md-0" type={'submit'}>
                                    {t.buttons.save}
                                </Button>
                            </div> :
                            <Button className="mt-4 mx-auto mx-md-0" type={'submit'}>
                                {t.buttons.save}
                            </Button>
                    }</> :
                    <>
                        {isWebview() && isMobile ? 
                            <div className="bottom_controller no_shadow d-flex justify-content-center">
                                <Anchor href='#' className="mb-5 mt-2 mx-auto mx-md-0" type={'button'} onClick={()=>openTfApp('APP#PROFILE#EDIT_PROFILE')}>
                                   <strong><u>{t.account.go_to_profile}</u></strong> 
                                </Anchor>
                            </div> 
                             : null
                        }
                    </>
                }
                
                
            </Form>
        </div>
        
    )
}