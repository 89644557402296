import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_exam_id: null,
  is_performing: false
};

export const examSlice = createSlice({
  name: 'currentExam',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentExam: (state, action) => {
        state.current_exam_id = action.payload.exam_id
        state.is_performing = true
    },
    resetCurrentExam: (state) => {
        state.current_exam_id = null
        state.is_performing = true
    },
  },
});

export const { setCurrentExam, resetCurrentExam } = examSlice.actions;

export const selectCurrentExamId = (state) => state.currentExam.current_exam_id;

export default examSlice.reducer;
