// Import the main component
import { LocalizationMap, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import it_IT from '@react-pdf-viewer/locales/lib/it_IT.json';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import lock_img from '../images/lock.svg'
import { selectTranslations } from '../../../services/i18n/i18nSlice';
import { useSelector } from 'react-redux';
import { selectAppPersonalizations } from '../../../services/utils/appInfoSlice';
import { API_URL } from '../../../config/constants';
import {TitleH2} from '../../../components/TitleH2';
import { selectAppAuthToken } from '../../../services/auth/authSlice';

export default function PrivacyModule () {

    const t = useSelector(selectTranslations) 
    const userAuthToken = useSelector(selectAppAuthToken)
    const personalizations = useSelector(selectAppPersonalizations)
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div>
            <div className="mt-0 mt-md-4 page_title">
                <img src={lock_img} />
                <TitleH2 className="mb-0">{t.account.terms}</TitleH2>
            </div>
            <div className='mt-4' style={{height:'75vh',width:"100%"}}>
                <Viewer localization={it_IT} fileUrl={`${API_URL}${personalizations?.privacy_document_url}`} 
                plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance
                ]}/>
            </div>
        </div>
    )
}