import {Text} from "../Text";
import {TitleH2} from "../TitleH2";
import Modal from 'react-bootstrap/Modal';
import {Button} from "../Button";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";

export default function ConfirmModal ({props}) {

    const {show, onConfirm, onClose, icon, title, text, confirmCta, closeCta} = props
    const t = useSelector(selectTranslations)

    return (
        <Modal
            show={show}
            onHide={onClose}
            keyboard={false}
            centered
        >
            <Modal.Body className="text-center">
                {icon &&
                    <img src={icon} alt="" className="mb-2 mx-auto" />
                }
                <TitleH2 className="text-center">{title}</TitleH2>
                <Text className="text-center mb-4">{text}</Text>
                {onConfirm !== null ? 
                    <Button className="mt-2 w-100 mx-auto" onClick={onConfirm} color="primary">{confirmCta || t.modals.confirm}</Button>
                    : null
                }
                {onClose !== null ? 
                    <Button className="mt-2 w-100 mx-auto" onClick={onClose} color="secondary">{closeCta || t.modals.close}</Button>
                    : null
                }
            </Modal.Body>
        </Modal>
    )
}