import { useState } from "react";

export default function SecureImage({imageUrl, className='', height='120px', width='120px'}){

    const [isLoaded, setIsLoaded] = useState(false)

    return(
        <div className={className} >
            <img src={imageUrl} alt="" style={!isLoaded ? {height:0,width:0} : {width:'100%'}} onLoad={()=>{setIsLoaded(true)}} />
            {!isLoaded ? 
               <div className="loader" style={{height:height, width:width}}/> : null}
        </div>
    )
}