import { API_URL } from "../config/constants";
import axios from "axios";
import axiosRetry from 'axios-retry'; 

export default function callServer ({
    endpoint,
    body = {},
    params = {},
    needAuthenticate = true,
    handleError = true,
    showLoading = true,
    retryIfFailed = false,
    isGet = false,
    needsBaseUrl = true,
    autoRetryTimes = 2,
    timeout = null,
}) {
    const api_url_end = API_URL + endpoint;

    const axiosClient = axios.create({})
    if (retryIfFailed) {
        axiosRetry(axiosClient, {
          retries: autoRetryTimes,
          retryDelay: retryCount => {
            return retryCount * 2000;
          },
          retryCondition: error => {
            return error.response.status >= 500 || error.response.status === 409;
          },
        });
    }

    axiosClient.interceptors.request.use((config) => {
        if (needAuthenticate) {
                config.withCredentials = true;
        }
        return config;
    });

    if(isGet){
        return axiosClient.get(api_url_end, {
            params: params,
            headers: get_headers('GET')
        }).then( response => {
            return handleResponse(response)
        }).catch( error => {
            return handleResponse(error?.response)
        })
    } else {
        return axiosClient.post(api_url_end, body, {
            params: params,
            headers: get_headers('POST')
        }).then( response => {
            return handleResponse(response)
        }).catch( error => {
            return handleResponse(error?.response)
        })
    }
}

const get_headers = (method = "POST") => {
    if(method === "POST")
        return { 
            'Content-Type': 'application/json', 
            'Accept': 'application/json', 
            'charset': 'utf-8'
        }
    else if(method === "GET")
        return {}
    
}

const handleResponse = (response) => {
    if (response == null) return { success: false, data: null };
    if (response.success === false || response.status >= 400 || response?.data?.detail) return { success: false, error: response?.data?.detail ? response?.data?.detail : response?.statusText };
    return { success: true, data: response.data };
}