import BottomSheet from "../../../components/_modals/BottomSheet";
import training_img from '../../../assets/images/folders/training.svg'
import { Text } from "../../../components/Text";
import { Button } from "../../../components/Button";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import { Link } from "react-router-dom";
import { TitleH2 } from "../../../components/TitleH2";


export default function StartTrainingModal ({show, folder, onClose}) {
    
    const t = useSelector(selectTranslations)
    
    return(
        <BottomSheet isOpen={show} onClose={onClose} showExit={true}>
            <div className='mb-5 mb-md-0 mt-3 px-4 text-center'>
                <img src={training_img} style={{minWidth:"32px"}} />
                <TitleH2 className="mt-2">{folder?.training?.training_title}</TitleH2>
                <Text>{folder?.training?.description}</Text>
                <Link to={`/training/perform/${folder?.folder_id}`} replace={true}>
                    <Button className="mt-2 mx-auto">{t.folder.start_training}</Button>
                </Link>
            </div>
        </BottomSheet>
    )
}