import { useDispatch, useSelector } from "react-redux";

import { selectTranslations } from "../../services/i18n/i18nSlice";
import { selectAppPersonalizations } from "../../services/utils/appInfoSlice";
import { confirmAccountEmailWithThefacultyToken, selectAppAuth } from "../../services/auth/authSlice";
import { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";


import {Text} from "../../components/Text";
import {TitleH1} from "../../components/TitleH1";
import {Button} from "../../components/Button";
import { checkUserFields, selectUserInfo } from "../../features/User/userSlice";
import { API_URL } from "../../config/constants";

import error_img from '../../assets/images/error.svg'
import Spinner from 'react-bootstrap/Spinner';

import './LoginScreen.css'

export default function ConfirmTFToken () {

    const personalizations = useSelector(selectAppPersonalizations)
    const userAuth = useSelector(selectAppAuth)
    const userInfo = useSelector(selectUserInfo)
    const missingFields = useSelector(checkUserFields)
    const t = useSelector(selectTranslations);
    const dispatch = useDispatch();
    const navigate = useNavigate()


    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString)
        const login_token = urlParams.get('thefaculty_token')
        if(!login_token)
            navigate('/', {replace: true})
        else {
            dispatch(confirmAccountEmailWithThefacultyToken({login_token: login_token})) 
        }
    },[])
    

    return(
        <div id="loginScreen">
            { !userAuth?.auth_token? 
            <>
                { userAuth.authPending &&  
                    <div className="fh-div d-flex flex-column align-items-center justify-content-between">
                        <div className="top">
                            <img src={`${API_URL}${personalizations?.logo_url}`} className="mx-auto d-block" />
                            <TitleH1 className="text-center mt-2">{t.login.waiting_token_login}</TitleH1>
                            <Text className="text-center mt-2">{t.login.waiting_token_login_text}</Text>
                            <div className="d-flex justify-content-center">
                                <Spinner />
                            </div>
                        </div>
                    </div> }
                { userAuth.error &&  <div className="fh-div d-flex flex-column align-items-center justify-content-between">
                        <div className="top">
                            <img src={error_img} className="mx-auto d-block" style={{maxWidth:'150px'}}/>
                            <TitleH1 className="text-center mt-2">{t.login.error_token_login}</TitleH1>
                            <Text className="text-center mt-2">{t.login.error_token_login_text}</Text>
                            <Link to={'/'}>
                                <Button type={'primary'} className="mt-3 mx-auto">{t.buttons.back_home}</Button>
                            </Link>
                        </div>
                    </div>
                }
            </> : 
            <>
                {   
                    userInfo.user_data && userAuth ?
                    <>
                        { userAuth?.admin_check_required || userInfo?.user_data?.state === "waiting_admin_check" ? 
                            <Navigate to={'/login/waiting_approval'} replace />
                        :
                            <>
                                { missingFields || !userInfo.user_data?.legal_checks?.external_legal_check ?
                                    <Navigate to={'/login/onboarding'} replace />
                                :
                                    <Navigate to={'/'} replace />
                                }
                            </>
                        }
                    </> : ""
                }
            </>
            }
        </div>
    )
}