import pdf from '../../../assets/images/folders/pdf.png'
import image from '../../../assets/images/folders/image.png'
import { API_URL } from '../../../config/constants'
const FILE_IMAGE_MAPPING = {
    'pptx': pdf,
    'pdf': pdf,
    'png': image,
}

export default function File ({file}) {
    return (
        <a className="file" href={`${API_URL}/get_file?file_id=${file.file_id}`} target='_blank' download={true}>
            <img src={FILE_IMAGE_MAPPING[file.filetype || 'pdf']} alt=""/>
            <span>{file.name}</span>
        </a>
    )
}


// Improved Version --> with content view
// export default function File ({file, folder}) {
//     return (
//         <Link className="file" to={`/file/view/${folder.folder_id}/${file.file_id}`}>
//             <img src={FILE_IMAGE_MAPPING[file.filetype || 'pdf']} alt=""/>
//             <span>{file.name}</span>
//         </Link>
//     )
// }