
import { useSelector } from 'react-redux';
import NavBar from '../../components/Navigation/NavBar';
import { selectLoadingState, selectNavbarHideMobileState, selectNavbarHideState } from '../../services/global/globalSlice';
import MainLoader from './MainLoader';

import './RootScreen.css'
import { isMobile } from 'react-device-detect';
import { Worker } from '@react-pdf-viewer/core';
import { selectAppInfo } from '../../services/utils/appInfoSlice';
import OnboardingModal from '../../components/_ofa_component/OnboardingModal';
import GlobaErrorModal from '../../features/Error/GlobalErrorModal';


export default function RootScreen ({children}) {

    const isLoading = useSelector(selectLoadingState)
    const hideNavbar = useSelector(selectNavbarHideState)
    const hideNavbarMobile = useSelector(selectNavbarHideMobileState)
    const appInfo = useSelector(selectAppInfo)

    return(
        <div id="rootScreen">
            <NavBar hide={hideNavbar || (isMobile && hideNavbarMobile)} />
            <div 
                id="appContainer"
                className={hideNavbar || (isMobile && hideNavbarMobile) ? 'extended' : '' }
            >
                {isLoading ? <MainLoader /> : ""}
                {children}
                {
                    appInfo?.is_ofa &&
                        <OnboardingModal  />
                }
            </div>
            <GlobaErrorModal/>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js" />
        </div>
    )
}
