
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import { useEffect } from "react";

import img_lock from '../../../assets/images/locker.svg'
import img_close from '../../../assets/images/close.svg'
import { Link } from "react-router-dom";
import { selectTranslations } from '../../../services/i18n/i18nSlice';
import { TitleH2 } from '../../../components/TitleH2';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';

export default function OfaModal (props) {

    const {show, onClose} = props
    const t = useSelector(selectTranslations)

    return (
        <Modal
            show={show}
            onHide={onClose}
            keyboard={false}
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-end">
                    <img src={img_close} alt="" className="pointer" onClick={onClose} />
                </div>
                <div className="d-flex justify-content-center">
                    <img src={img_lock} alt="" className="mx-center" />
                </div>
                <TitleH2 className="text-center mt-2">{t.ofa.modal_title}</TitleH2>
                <Text className="text-center mb-4">{t.ofa.modal_text}</Text>
                <Link to={'/exams/'} replace={true}>
                    <Button color="secondary" className="mx-auto mt-3 w-100">
                        {t.ofa.modal_button}
                    </Button>
                </Link>
            </Modal.Body>
        </Modal>
    )
}