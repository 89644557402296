import { useSelector } from "react-redux";
import { selectAppPersonalizations } from "../services/utils/appInfoSlice";
import styled from 'styled-components'


const DefaultAnchor = styled.a`
  /* Adapt the colors based on primary prop */
  color: ${props => props.$color || 'var(--primary-color)'};
  font-size: ${props => props.$fontSize ? props.$fontSize : '16px'};
  line-height: 120%;
  font-weight: 500;
`;

export default function Anchor({ size, className='', fontSize='', target='', href='', onClick=null, disabled=false, children }) {

    const personalizations = useSelector(selectAppPersonalizations);

    return (
        <DefaultAnchor className={className} href={href} onClick={onClick} disabled={disabled} $fontSize={fontSize} target={target} $color={personalizations?.link?.color}>
            {children}
        </DefaultAnchor>
    )
}

