import { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router";
import { fetchGetScoreboard } from '../ExamsApi';
import { selectUserData } from '../../../features/User/userSlice';
import { useSelector } from "react-redux";
import debounce from 'lodash.debounce'

import '../assets/Exam.css'
import SecureImage from '../../../components/_partials/SecuredImage';
import { Avatar } from '../../../components/_partials/Avatar';
import { API_URL } from '../../../config/constants';

import score_img from '../assets/images/score.svg'

export default function Scoreboard(props) {
    const location = useLocation()
    const [scoreboardData, setScoreboardData] = useState([])
    const [scoreboardNavigationData, setScoreboardNavigationData] = useState({
        hasMoreTop: true,
        hasMoreBottom: true,
        currentTop: 0
    })
    const [scroll, setScroll] = useState(0)
    const userData = useSelector(selectUserData)
    const exam_id = props.examId

    const topRef = useRef()
    const ref = useRef()

    const loadScoreboard = async (e) => {
        console.log(e);
        const response = await fetchGetScoreboard({exam_id: exam_id, limit:30})
        setScoreboardData([...scoreboardData, ...response?.data?.scoreboard])
        setScoreboardNavigationData({...scoreboardNavigationData, hasMoreBottom: response.data?.next_data?.bottom?.cursor !== null, hasMoreTop: response.data?.next_data?.top?.cursor !== null, currentTop: response.data?.scoreboard[0].position, ...response.data?.next_data})
        if(ref?.current)
        setTimeout(()=>{
            ref.current.scrollIntoView({
                behavior: 'smooth',
            });
        },300)
    }

    const loadMoreScoreboardTop = async (e) => {
        console.log('load top fetch');
        const response = await fetchGetScoreboard({exam_id: exam_id, limit:30, ...scoreboardNavigationData?.top})
        const _new_data = response?.data?.scoreboard?.reverse()
        setScoreboardData([ ..._new_data, ...scoreboardData])
        console.log(topRef.current);
            topRef.current.scrollIntoView({
                behavior: 'smooth',
            })
        setScoreboardNavigationData({...scoreboardNavigationData, hasMoreTop: _new_data?.length > 0, currentTop: _new_data[0]?.position, ...response.data?.next_data})
    }

    const loadMoreScoreboardBottom = async (e) => {
        console.log('load bottom fetch');
        const response = await fetchGetScoreboard({exam_id: exam_id, limit:30, ...scoreboardNavigationData?.bottom})
        setScoreboardData([...scoreboardData, ...response.data.scoreboard])
        setScoreboardNavigationData({...scoreboardNavigationData, hasMoreBottom: response.data?.scoreboard?.length > 0, ...response.data?.next_data})
    }

    const scrollToTop = () => {
        topRef.current.scrollIntoView({
            behavior: 'smooth',
        })
    }

    const _handleScroll = (e) => {
        const obj = e.target
        if( scroll < obj.scrollTop)
            if( obj.scrollTop >= (obj.scrollHeight - obj.offsetHeight - 50)){
                console.log('load bottom');
                if(scoreboardNavigationData?.hasMoreBottom)
                    loadMoreScoreboardBottom()
            }
                
        if( scroll > obj.scrollTop)
            if( obj.scrollTop < 50){
                console.log('load top');
                if(scoreboardNavigationData?.hasMoreTop)
                    loadMoreScoreboardTop()
            }
        setScroll(obj.scrollTop)
    }

    const handleScroll = debounce(_handleScroll,200)

    useEffect(()=>{ 
        loadScoreboard()
    },[])

    return(
        <div>
            <button onClick={scrollToTop}>Scroll up</button>
            <div style={{height:'30vh',overflow:'auto'}} className='scoreboard' onScroll={handleScroll}>
                {scoreboardData.map((s,i)=>{
                    return(
                        <div key={i} className={`scoreboard_card py-5 ${s.user_data?.user_id === userData.user_id ? 'active' : ''}`} ref={s.user_data?.user_id === userData.user_id ? ref : (s.position === scoreboardNavigationData.currentTop ? topRef : null)}>
                            <div className='scoreboard_card__content'>
                                <span className='scoreboard_card__position'>{s.position}°</span>
                                <div className="scoreboard_card__image ms-2">
                                    {s.user_data?.profile_image_url ? 
                                        <SecureImage imageUrl={`${API_URL}${s.user_data?.profile_image_url}`} />
                                    : <Avatar avatar_id={s.user_data?.profile_avatar_id || 0} />
                                    }
                                </div>
                                <span className='scoreboard_card__name ms-3'>{s.user_data?.firstname}</span>
                            </div>
                            <div className='scoreboard_card__score'>
                                <span className=''>{s.total_score} pt</span>
                                <img className="ms-2" src={score_img} />
                            </div>
                            
                        </div>
                    )
                })}
            </div>
        </div>
    )
}