import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../services/i18n/i18nSlice"
import { TitleH1 } from "../../components/TitleH1"
import { useNavigate, useParams } from "react-router-dom"
import { selectLoadingState, setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice"
import { useEffect, useState } from "react"
import { Text } from "../../components/Text"
import { fetchGetFolder } from "./FolderApi"
import { toast } from "react-toastify"
import SubFolders from "./_components/SubFolders"

import './FolderScreen.css'
import { Button } from "../../components/Button"
import { TitleH3 } from "../../components/TitleH3"
import FolderTopBar from "./_components/FolderTopBar"
import { TitleH2 } from "../../components/TitleH2"

import video_img from '../../assets/images/folders/video.svg'
import files_img from '../../assets/images/folders/files.svg'
import link_img from '../../assets/images/folders/link.svg'
import correct_img from '../../assets/images/folders/correct.svg'
import error_img from '../../assets/images/error.svg'
import incomplete_img from '../../assets/images/ofa/incomplete_folder.svg'

import File from "./_components/File"
import Video from "./_components/Video"
import Link from "./_components/Link"
import OfaModal from "./_components/OfaModal"
import { OFA_TENANT, TENANT } from "../../config/constants"
import { fetchGetUserData } from "../../features/User/userApi"
import { GetFromLocalStorage, SaveToLocalStorage } from "../../utils/utils"
import { selectAppInfo } from "../../services/utils/appInfoSlice"
import { loadUserProfileData } from "../../features/User/userSlice"
import DesktopSheet from "../../components/_ofa_component/DesktopSheet"
import OfaExamBanner from "../ExamScreen/_ofa_components/OfaExamBanner"

export default function FolderScreen () {

    const t = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const appInfo = useSelector(selectAppInfo)

    const {folder_id} = useParams()
    const [folderData, setFolderData] = useState({})
    const [loadError, setLoadError] = useState(false)

    //OFA-ONLY
    const [showOfaModal, setShowOfaModal] = useState(false)

    const hasContent = folderData?.files?.length > 0 || folderData?.video?.length > 0 || folderData?.links?.length > 0

    const checkUserGroups = async () => {
        if(OFA_TENANT.includes(TENANT) && !GetFromLocalStorage('ofa_exam_modal_show') && GetFromLocalStorage('ofa_onboarding')){
            const response = await fetchGetUserData()
            if(response.success){
                if (response.data?.groups?.includes(appInfo?.personalizations?.ofa_settings?.video_completion_group_id)){
                    setShowOfaModal(true)
                    SaveToLocalStorage('ofa_exam_modal_show', true)
                    dispatch(loadUserProfileData())
                }
            }
        }
    }

    const loadFolderDetails = async () => {
        const response = await fetchGetFolder({...(folder_id && {folder_id: folder_id})})
        if (response.success){
            if(!response.data?.parent_folder_id){
                dispatch(setNavbarHideMobile(false))
                setFolderData(response.data)
            } else {
                if(response.data
                    && ( response.data?.files?.length === 0 || !response.data?.files)
                    && ( response.data?.videos?.length === 0 || !response.data?.videos)
                    && ( response.data?.links?.length === 0 || !response.data?.links)
                ){
                    navigate(`/folder/${response.data?.parent_folder_id}`, {replace:true})
                } else {
                    const response_parent = await fetchGetFolder({folder_id: response.data?.parent_folder_id})
                    if (response_parent.success){
                        setFolderData({
                            ...response.data,
                            parent_folder_data: response_parent.data
                        })
                    }
                    dispatch(setNavbarHideMobile(true))
                }
            }
            checkUserGroups()
            dispatch(setLoading(false))
        } else {
            toast.error(t.training.error_loading_folder)
            dispatch(setLoading(false))
            setLoadError(true)
        }
    }

    const startTraining = () => {
        navigate(`/training/perform/${folderData.folder_id}`,{replace:true})
    }
    
    useEffect(()=>{
        dispatch(setNavbarHideMobile(false))
        dispatch(setNavbarHide(false))
        dispatch(setLoading(true))
        loadFolderDetails()
    },[])

    useEffect(()=>{
        dispatch(setLoading(true))
        setLoadError(false)
        setFolderData(null)
        loadFolderDetails()
    },[folder_id])


    return (
        <section className="content_section pb-4">
            {
                folderData?.parent_folder_id ? <FolderTopBar folderData={folderData} t={t} /> : null
            }
            {
                folderData && !folderData?.parent_folder_id &&
                    <OfaExamBanner />
            }
            
            {folderData?.folder_id ? 
                <>
                    <div className={`${folderData?.parent_folder_id ? 'mt-5': 'mt-0'} d-block d-md-flex justify-content-between align-items-start`}>
                        <div className="">
                            <TitleH1 className={` text-md-start ${folderData?.parent_folder_id ? 'text-center': ''}`}>
                                {folderData?.name || t.training.page_title}
                            </TitleH1> 
                            {folderData?.description || !folder_id  ?
                                <Text className={`text-md-start ${folderData?.parent_folder_id ? 'text-center': ''}`}>
                                    {folderData?.description || (!folder_id ? t.training.home_description : '')}
                                </Text>
                            : <>
                                {(appInfo.is_ofa && hasContent) && 
                                    <Text className={`text-center text-md-start`}>
                                        Completa <img src={correct_img} alt=""/> tutte le unità per accedere alla verifica finale 
                                    </Text>
                                }
                            </>}
                        </div>
                        {
                            folderData?.training?.ready ?
                                <Button className="mx-auto mx-md-0 mt-2 mt-md-0" onClick={startTraining}>
                                    {folderData?.training?.training_title}
                                </Button>
                            : null
                        }
                    </div>
                    <SubFolders parentFolder={folderData} />
                    <div className="folder-content pb-5">
                        {hasContent && <TitleH2 className="mt-4 text-capitalize">{t.training.materials}</TitleH2>}
                        
                        { folderData?.videos?.length > 0 ? 
                        <>
                            <div className="d-flex mt-3 mb-2 align-items-center">
                                <img src={video_img} alt="" />
                                <TitleH3 className="ms-2 text-capitalize">{t.folder.videos} <img src={folderData?.videos?.find(v => !v.completed) ? incomplete_img : correct_img} className='ms-2' alt="" /></TitleH3> 
                            </div>
                        
                            <div className="video_list mt-3">
                                {
                                    folderData?.videos?.map((video,file_index)=>{
                                        return(
                                            <Video onVideoEnd={loadFolderDetails} video={video} folder={folderData} key={file_index}/>
                                        )
                                        
                                    })
                                }
                            </div>
                        </>
                       : null }
                       { folderData?.files?.length > 0 ? 
                        <>
                            <div className="d-flex mt-4 mb-2 align-items-center">
                                <img src={files_img} alt="" />
                                <TitleH3 className="ms-2 text-capitalize">{t.folder.files}</TitleH3> 
                            </div>
                            <div className="file_list mt-3">
                                {
                                    folderData?.files?.map((file,file_index)=>{
                                        return(
                                            <File file={file} folder={folderData} key={file_index}/>
                                        )
                                        
                                    })
                                }
                            </div>
                        </>
                        : null }
                       { folderData?.links?.length > 0 ? 
                        <>
                            <div className="d-flex mt-4 mb-2 align-items-center">
                                <img src={link_img} alt="" />
                                <TitleH3 className="ms-2 text-capitalize">{t.folder.links}</TitleH3> 
                            </div>
                        
                            <div className="link_list mt-3">
                                {
                                    folderData?.links?.map((link,file_index)=>{
                                        return(
                                            <Link link={link}  key={file_index}/>
                                        )
                                        
                                    })
                                }
                            </div>
                        </>
                       : null }
                    </div>
                </> : 
                <>
                    { loadError &&
                        <div className="mt-5 pt-5 d-flex flex-column align-items-center justify-content-between">
                            <div className="top">
                                <img src={error_img} className="mx-auto d-block" style={{maxWidth:'150px'}}/>
                                <TitleH1 className="text-center mt-2">{t.error.error_page_title}</TitleH1>
                                <Text className="text-center mt-2">{t.error.error_page_load}</Text>
                                <Button type={'primary'} onClick={()=>{window.location.reload()}} className="mt-3 mx-auto">{t.buttons.reload_page}</Button>
                            </div>
                        </div>
                    }
                </>
            }
            {
                appInfo.is_ofa ? 
                    <OfaModal show={showOfaModal} onClose={()=>setShowOfaModal(false)} />
                : null
            }
            {
                appInfo.is_ofa && 
                    <DesktopSheet />
            }
        </section>
    )
}