import {Text} from "../Text";
import Modal from 'react-bootstrap/Modal';
import {Button} from "../Button";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useState } from "react";

import img_1 from '../../assets/images/thefaculty.svg'
import img_close from '../../assets/images/close.svg'
import { TitleH1 } from "../TitleH1";
import { SaveToLocalStorage } from "../../utils/utils";

export default function WaitingModal ({showClose=false}) {

    
    const t = useSelector(selectTranslations)

    const [show, setShow] = useState(true)

    const closeModal = () => {
        SaveToLocalStorage('first_login_ofa',true)
        setShow(false)
    }

    return (
        <Modal
            show={show}
            keyboard={false}
            centered
            size="md"
        >
            <Modal.Body className="text-center" style={{minHeight:'40vh'}}>
                <div className="d-flex justify-content-end mb-2">
                    <img src={img_close} alt="" className="pointer" onClick={()=>setShow(false)} />
                </div>
                <img src={img_1} style={{maxWidth:'60%'}} alt="" />
                <TitleH1 className="mt-3 px-2">{t.ofa.login.tf_account}</TitleH1>
                <Text className="mt-2 mb-2 px-2">{t.ofa.login.tf_account_text}</Text>
                { showClose &&
                <Button className="w-100 mx-auto mt-3" onClick={closeModal}>
                    {t.buttons.close}
                </Button> }
            </Modal.Body>
        </Modal>
    )
}