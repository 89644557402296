import { useEffect, useRef, useState } from "react";
import { fetchGetFolder, fetchGetVideo, fetchUpdateUserVideoStatistics } from "./FolderApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../features/Error/errorSlice";
import { useLocation, useParams } from "react-router-dom";
import { setLoading } from "../../services/global/globalSlice";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { TitleH2 } from "../../components/TitleH2";

import correct_img from '../../assets/images/folders/correct.svg'

import FolderTopBar from "./_components/FolderTopBar";
import debounce from "lodash.debounce";
import { Spinner } from "react-bootstrap";
import { API_URL } from "../../config/constants";


export default function ContentViewer() {

    const [folderData, setFolderData] = useState(null)
    const dispatch = useDispatch()

    const videoRef = useRef()

    const location = useLocation()
    const {file_id, folder_id} = useParams()

    const t = useSelector(selectTranslations)

    const loadFolderData = async () => {
        const response = await fetchGetFolder({...(folder_id && {folder_id: folder_id})})
        if (response.success){
            setFolderData(response.data)
            dispatch(setLoading(false))
        } else {
            toast.error(t.training.error_loading_folder)
            dispatch(setLoading(false))
        }
    }

    useEffect(()=>{
        if(location.state?.folderData)
            setFolderData(location.state?.folderData)
        else    
            loadFolderData()
    },[location])

    return(
        <section className="content_section">
            {
                folderData ? <FolderTopBar current={true} folderData={folderData} t={t} /> : null
            }
            
            <div className="mt-5">
                {
                    file_id &&
                        <iframe src={`${API_URL}/get_file?file_id=${file_id}`} className="w-100" style={{height:'100%'}}/>
                }
            </div>
        </section>
    )
}