import { useEffect, useRef, useState } from "react"
import "./Question.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import { Form, OverlayTrigger, Popover, FormCheck } from "react-bootstrap";

import wrong_img from '../../../assets/images/questions/wrong.svg'
import correct_img from '../../../assets/images/questions/correct.svg'

import SecureImage from "../../../components/_partials/SecuredImage";
import { isMobile } from "react-device-detect";
import { API_URL } from "../../../config/constants";
import ImageZoom from "../../../components/ImageZoom/ImageZoom";
import QuestionPill from "./QuestionPill";
import { Button } from "../../../components/Button";

export default function Question(props){

    const t = useSelector(selectTranslations)
    const [HasAnswer,setHasAnswer] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)

    const questionRef = useRef()

    const _handleAnswerQuestion = (type, data, value, check = false) => {
        if(!props.data.show_results)
            props.handleAnswerQuestion(type, data, value, check)
    }

    const handleAnswerBaseQuestion = (event) =>{
        const target = event.target;
        const value = target.type === 'checkbox'  ? target.checked : target.value;
        const name = target.name;
        _handleAnswerQuestion(props.data.question_type, props.data, value);
    }
    const handleAnswerMultiQuestion = (event) => {
        const target = event.target;
        const checked = target.checked;
        const value = target.value;
        _handleAnswerQuestion(props.data.question_type, props.data, value, checked);
    }
    const handleAnswerBlockQuestion = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        _handleAnswerQuestion("block", props.data, value);
    }
    const handleSendAnswer = () => {
        props.handleSendAnswer(props.data.question_type, props.data);
    }

    const formatText = (original_text = "") => {
        return original_text?.replaceAll("\n", '<br />')?.replaceAll("$$","$")
    }

    useEffect(()=>{
        setImageLoaded(false)
    },[props?.data?.image_url])

    useEffect(()=>{
        questionRef.current.animate(
        {
            opacity: [0, 1]
        },
        500
        );
    },[props.data.question_id])

    return (
        <div className="col-12 col-md-9 exam_question_container training_container px-3 px-md-4 pb-5" ref={questionRef}>
            {
                props.data? 
                    <>
                        {!isMobile ? <p className="text-black"><img src={correct_img} alt="" /> <strong>{props.navigationData.correct_count}</strong><span style={{color:'#454545',fontWeight:'500'}}>/{parseInt(props.navigationData.questions_count)}</span> </p> : null}
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: props.data.text }} />
                        {
                            props.data.paper && props.data.virtual_question_number != undefined ?
                                <div className="reader_preview" onClick={props.handleOpenReader}>
                                    <p style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{ __html: formatText(props.data.paper) }} />
                                </div>
                            : ""
                        }
                        { props.data.image_url ?
                            <> 
                                <ImageZoom>
                                    <SecureImage 
                                        imageUrl={`${API_URL}${props.data.image_url}`} 
                                        alt="Question Image" 
                                        className="mt-3 w-100" 
                                    />
                                </ImageZoom>
                                <small className="d-block text-secondary">{t.question.click_on_image}</small>
                            </>
                        : ""}
                        {
                            props.data.additional_material_instructions ? <p className="mt-2 text-secondary">{props.data.additional_material_instructions}</p> : ""
                        }
                        {
                            props.data.question_type === "base" ?
                                <div className="answers_list">
                                    {props.data.answers.map((answer) => {
                                        return(
                                            <label className={"answer " 
                                                    + ( answer.answer_number == props.data.selected_answer_number ? "selected " : "" ) 
                                                    + (props.data.show_results && answer.answer_number == props.data.selected_answer_number ? 
                                                        ( props.data.correct_answers?.includes(parseInt(answer.answer_number)) ? "correct_answer " : "wrong_answer" ) : "" )
                                                    + (props.data.show_results && answer.answer_number != props.data.selected_answer_number ? 
                                                        ( props.data.correct_answers?.includes(parseInt(answer.answer_number)) ? "correct_answer" : "" ) : "" )
                                                    } key={answer.answer_number} htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}>
                                                
                                                <FormCheck>
                                                    <FormCheck.Input  
                                                        type={"radio"}
                                                        className="hidden_radio"
                                                        name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        value={answer.answer_number} 
                                                        onChange={handleAnswerBaseQuestion}
                                                        checked={answer.answer_number == props.data.selected_answer_number ? true : false}
                                                    />
                                                    <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} className="d-flex w-100 justify-content-between align-items-center">
                                                        <div>
                                                            <span className="answer_label_number">
                                                                {String.fromCharCode(65 + answer.answer_number)}.
                                                            </span>
                                                            <span dangerouslySetInnerHTML={{ __html: answer.text }}/>
                                                        </div>
                                                        <div>
                                                            <img src={correct_img} alt="" className="answer_feedback correct_feedback" />
                                                            <img src={wrong_img} alt="" className="answer_feedback wrong_feedback" />
                                                        </div>
                                                    </FormCheck.Label>
                                                </FormCheck>
                                            </label>
                                        )
                                    })}
                                </div>
                            : ""
                        }
                        {
                            props.data.question_type === "multi" || props.data.question_type === "survey"?
                            <>
                                <div className="answers_list">
                                    {props.data.answers.map((answer) => {
                                        return (
                                            <label className={"answer " 
                                                + (props.data.selected_answer_numbers && props.data.selected_answer_numbers.includes(parseInt(answer.answer_number)) ? "selected " : "")
                                                + (props.data.show_results && props.data.selected_answer_numbers.includes(parseInt(answer.answer_number)) ? 
                                                    ( props.data.correct_answers?.includes(parseInt(answer.answer_number)) ? "correct_answer " : "wrong_answer" ) : "" )
                                                + (props.data.show_results && !props.data.selected_answer_numbers.includes(parseInt(answer.answer_number)) ? 
                                                    ( props.data.correct_answers?.includes(parseInt(answer.answer_number)) ? "correct_answer" : "" ) : "" )
                                                }  key={answer.answer_number} htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}>
                                                
                                                <FormCheck>
                                                    <FormCheck.Input  
                                                        type={"checkbox"}
                                                        name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        value={answer.answer_number} 
                                                        onChange={handleAnswerMultiQuestion}
                                                        checked={props.data.selected_answer_numbers !== null && props.data.selected_answer_numbers.includes(parseInt(answer.answer_number)) ? true : false}
                                                />
                                                    <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} className="d-flex w-100 justify-content-between align-items-center">
                                                            <div>
                                                                <span className="answer_label_number">
                                                                    {String.fromCharCode(65 + answer.answer_number)}.
                                                                </span>
                                                                <span dangerouslySetInnerHTML={{ __html: answer.text }}/>
                                                            </div>
                                                            <div>
                                                                <img src={correct_img} alt="" className="answer_feedback correct_feedback" />
                                                                <img src={wrong_img} alt="" className="answer_feedback wrong_feedback" />
                                                            </div>
                                                        </FormCheck.Label>
                                                </FormCheck>
                                            </label>
                                        )
                                    })}
                                </div>
                                <div className="bottom_controller pb-5 pb-md-4">
                                    <Button className="mx-auto w-100" disabled={!props?.data?.selected_answer_numbers || props?.data?.selected_answer_numbers?.length <= 0} onClick={handleSendAnswer} >
                                        {t.buttons.confirm}
                                    </Button>
                                </div>
                            </>
                                : ""
                        }
                        {
                            props.data.question_type === "block" ?
                                <div className="answers_list">
                                    <div className="answers_list">
                                        {props.data.answers.map((answer) => {
                                            return(
                                                <label className={"answer " 
                                                + ( answer.answer_number == props.data.selected_answer_number ? "selected" : "" )
                                                + (props.data.show_results && answer.answer_number == props.data.selected_answer_number ? 
                                                        ( props.data.correct_answers?.includes(parseInt(answer.answer_number)) ? "correct_answer " : "wrong_answer" ) : "" )
                                                + (props.data.show_results && answer.answer_number != props.data.selected_answer_number ? 
                                                    ( props.data.correct_answers?.includes(parseInt(answer.answer_number)) ? "correct_answer" : "" ) : "" )
                                                
                                                } key={answer.answer_number} htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}>
                                                    
                                                    <FormCheck>
                                                        <FormCheck.Input  
                                                            type={"radio"}
                                                            className="hidden_radio"
                                                            name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                            id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                            value={answer.answer_number} 
                                                            onChange={handleAnswerBlockQuestion}
                                                            checked={answer.answer_number == props.data.selected_answer_number ? true : false}
                                                        />
                                                        <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} className="d-flex w-100 justify-content-between align-items-center">
                                                            <div>
                                                                <span className="answer_label_number">
                                                                    {String.fromCharCode(65 + answer.answer_number)}.
                                                                </span>
                                                                <span dangerouslySetInnerHTML={{ __html: answer.text }}/>
                                                            </div>
                                                            <div>
                                                                <img src={correct_img} alt="" className="answer_feedback correct_feedback" />
                                                                <img src={wrong_img} alt="" className="answer_feedback wrong_feedback" />
                                                            </div>
                                                        </FormCheck.Label>
                                                    </FormCheck>
                                                </label>
                                            )
                                        })}
                                    </div>
                                </div>
                                : ""
                        }
                        {
                            props.data.pill && 
                                <QuestionPill pill={props.data.pill} show={props.navigationData.show_pill} onClose={props.handleClosePill} />
                        }

                    </>
                : ""
            }
        </div>
    )
}