import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { selectAppInfo, selectAppPersonalizations } from "../../services/utils/appInfoSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import {Text} from "../../components/Text";
import {TitleH1} from "../../components/TitleH1";
import { loadUserProfileData, selectUserInfo } from "../../features/User/userSlice";
import { API_URL } from "../../config/constants";

import ok_img from './Onboarding/images/answer.svg'
import qr_img from '../../assets/images/ofa/app-qr.svg'
import Spinner from 'react-bootstrap/Spinner';

import './LoginScreen.css'
import {Button} from "../../components/Button";
import { logout, setAdminCheckRequired } from "../../services/auth/authSlice";
import WaitingModal from "../../components/_ofa_component/WaitingModal";
import { isMobile } from "react-device-detect";

export default function AdminWaitingScreen () {

    const personalizations = useSelector(selectAppPersonalizations)
    const appInfo = useSelector(selectAppInfo)
    const userInfo = useSelector(selectUserInfo)
    const t = useSelector(selectTranslations);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [idInterval, setIdInterval] = useState(null)
    
    useEffect(()=>{
        setIdInterval(setInterval(()=>{
            dispatch(loadUserProfileData())
        }, 60000))
    },[])
    
    useEffect(()=>{
        if(userInfo?.user_data?.state === "active"){
            clearInterval(idInterval)
            setTimeout(()=>{
                dispatch(setAdminCheckRequired(false))
                if(appInfo?.is_ofa)
                    navigate('/', {replace:true})
                else
                    navigate('/login/onboarding', {replace:true})
            }, 5000)
        }
    },[userInfo?.user_data])

    return(
        <div id="waitingScreen">
            {userInfo?.user_data?.state === "waiting_admin_check" ?
                <div className="fh-div d-flex flex-column align-items-center justify-content-between fade-in-animation">
                    <div className="top">
                        <img src={`${API_URL}${personalizations?.logo_url}`} className="mx-auto d-block" />
                        <TitleH1 className="text-center loading_text mt-5">{appInfo.is_ofa ? t.ofa.login.tf_account : t.login.waiting_admin}</TitleH1>
                        <Text className="text-center mt-2">{appInfo.is_ofa ? t.ofa.login.tf_account_text : t.login.waiting_admin_text}</Text>
                        <div className="d-flex justify-content-center">
                            {
                            (appInfo.is_ofa && !isMobile) &&
                                <img src={qr_img} className="mx-auto" style={{maxWidth:'200px'}} alt="" />
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            <Spinner />
                        </div>
                    </div>
                    <div className="bottom">
                        <Button onClick={()=>dispatch(logout())}>
                            {t.login.wrong_email_used}
                        </Button>
                    </div>
                    {
                        appInfo.is_ofa && 
                            <WaitingModal />
                    }
                </div>
            :
                <div className="fh-div d-flex flex-column align-items-center justify-content-between fade-in-animation">
                    <div className="top">
                        <img src={`${API_URL}${personalizations?.logo_url}`} className="mx-auto d-block" />
                        <img src={ok_img} className="mx-auto mt-4 d-block" />
                        <TitleH1 className="text-center mt-5 loading_text">{appInfo.is_ofa ? t.login.email_verified_ofa : t.login.email_verified}</TitleH1>
                        <Text className="text-center mt-2">{appInfo.is_ofa ? t.login.email_verified_text_ofa :  t.login.email_verified_text}</Text>
                    </div>
                </div>
            }
        </div>
    )
}