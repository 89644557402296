export const normalizeModules = (modules) => {
    let _papers = [];
    let counter = 1;
    let _questions = []
    modules.forEach(module =>{
        module?.questions?.forEach(question => {
            if (question.question_type !== 'block') {
                _questions.push({
                    blocked_question: question.question && question.answers ? false : true,
                    ...question,
                    question_id: `${module.module_number}_${question.question_number}`,
                    virtual_question_number: counter,
                    module_number: module.module_number
                });
                counter += 1;
            }
            if (question.question_type === 'block') {
                const blocks = question.blocks ?? [];
                console.log(question);
                blocks.forEach((block, idx) => {
                    const isFirstBlock = idx === 0;
                    const isLastBlock = idx === blocks?.length - 1;
                    const blockQuestion = { 
                    ...question,
                    ...block,
                    isFirstBlock,
                    isLastBlock,
                    isBlockQuestion: true,
                    blocked_question: block.question && block.answers ? false : true,
                    question_id: `${module.module_number}_${question.question_number}_${block.block_number}`,
                    virtual_question_number: counter,
                    module_number: module.module_number
                    };
                    delete blockQuestion.blocks;
                    _questions.push(blockQuestion);
                    counter += 1;
                });
            }
            if(question.paper !== null && question.paper !== undefined && question.paper !== "") {
                _papers.push({
                    paper: question.paper,
                    question_number: question.question_number,
                    module_number: module.module_number,
                    fontSize: 20,
                    selected_text: []
                })
            }
        });
    });
    return {_questions, _papers};
};

export const isCorrectAnswer = (question) => {
    if(question.question_type === 'multi'){
        let _correct = true
        question?.answers?.forEach((a)=>{
            if( a.is_correct_answer && !question.selected_answer_numbers?.includes(parseInt(a.answer_number)))
                _correct = false
        })
        return _correct
    } else if (question.question_type === 'base' || question.question_type === 'block'){
        return question.answers.find(a=> a.answer_number === question.selected_answer_number && a.is_correct_answer )
    } else if(question.question_type === 'survey'){
        return true
    }
}