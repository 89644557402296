import CallServer from '../../api/CallServerV2';
export async function fetchCustomizationsSetting () {
    return await CallServer({
        endpoint: '/get_customization_settings',
        needAuthenticate: false,
        isGet: true
    })
} 

export async function fetchOfaSetting () {
    return await CallServer({
        endpoint: '/get_ofa_settings',
        needAuthenticate: false,
        isGet: true
    })
} 