import { Link } from 'react-router-dom'
import arrow_img from '../../../assets/images/left.svg'

export default function FolderTopBar ({t, folderData, current=false}) {
    return (
        <div className="folder_topbar">
            {
                folderData?.parent_folder_id || folderData?.folder_id ?
                    <Link to={`/folder/${current ? folderData?.folder_id : folderData?.parent_folder_id}`}>
                        <img src={arrow_img} alt=''/>
                        <span>{current ? folderData.name : folderData?.parent_folder_data?.name || t.buttons.back}</span>
                    </Link>
                : ""
            }
        </div>
    )
}