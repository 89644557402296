
import exam_img from '../../../assets/images/ofa/verifica.svg'
import wrong_img from '../../../assets/images/ofa/question.svg'
import arrow_img from '../../../assets/images/ofa/circle_arrow.svg'

import './style.css'
import { useSelector } from "react-redux";
import { selectUserData } from "../../../features/User/userSlice";
import { selectAppInfo } from "../../../services/utils/appInfoSlice";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchGetUserExams } from "../ExamsApi";

export default function OfaExamBanner () {

    const userInfo = useSelector(selectUserData)
    const appInfo = useSelector(selectAppInfo)
    const navigate = useNavigate()

    //OFA-ONLY
    const [hasPassedExam, setHasPassedExam] = useState(false)
    const [examsCount, setExamsCount] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const checkHistoryExams = async (reset = false) =>{
        const _params = {
            has_started:true
        }
        const response = await fetchGetUserExams(_params)
        if(response.success){
            if(response.data?.user_exams?.length > 0){
                if(response.data?.user_exams?.find(e => e?.thresholds?.find(t => t.threshold_type==="certificate_generation")?.has_been_passed))
                    setHasPassedExam(true)
                setExamsCount(response.data?.user_exams?.filter(e => e?.thresholds?.find(t => t.threshold_type==="certificate_generation"))?.length)
            }
            setLoaded(true)
       } else {
            console.error(response.error)
        }   
    }

    const navigateToExam = () => {
        navigate('/exams', {replace:true})
    }

    const navigateToContact = () => {
        navigate('/account', {replace:true, state:{activeModule:'contact'}})
    }

    useEffect(()=>{
        checkHistoryExams()
    },[])

    return (
        <>
            {
                loaded &&
                <>
                    {
                        (userInfo?.groups?.includes(appInfo?.personalizations?.ofa_settings?.video_completion_group_id) ||
                        userInfo?.groups?.includes(appInfo?.personalizations?.ofa_settings?.retry_exam_group_id)) && !hasPassedExam && examsCount < 2 ?
                        <>
                            <div className="ofa_banner_top pointer" onClick={navigateToExam}>
                                <img src={exam_img} alt="" />
                                <div className="ms-1">
                                    <h3 className="mb-0">Verifica ufficiale OFA sbloccata</h3>
                                    <p className="mb-0">Svolgi la verifica ufficiale e ottieni il certificato</p>
                                </div>
                                <img src={arrow_img} alt="" className="ms-2 pointer" />
                            </div>
                            <div className="mb-5 pt-3"></div>
                        </> : <>
                            {
                                (!hasPassedExam && examsCount >= 2) &&
                                <>
                                <div className="ofa_banner_top pointer" onClick={navigateToContact}>
                                    <img src={wrong_img} style={{maxWidth:'32px'}} alt="" />
                                    <div className="ms-1">
                                        <h3 className="mb-0">Verifica ufficiale non superata</h3>
                                        <p className="mb-0">Contattaci per sbloccare un ulteriore tentativo</p>
                                    </div>
                                    <img src={arrow_img} alt="" className="ms-2 pointer" />
                                </div>
                                <div className="mb-5 pt-3"></div>
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}