import { useDispatch, useSelector } from "react-redux";
import cancel_img from '../../assets/images/cancel.svg'

import { selectTranslations } from "../../services/i18n/i18nSlice";
import { selectAppInfo, selectAppPersonalizations } from "../../services/utils/appInfoSlice";
import { confirmAccountEmail, logout, resetVerificationTimeout, selectAppAuth, verifyAccountEmail } from "../../services/auth/authSlice";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';


import {Text} from "../../components/Text";
import {TitleH1} from "../../components/TitleH1";
import {Button} from "../../components/Button";
import PinInput from 'react-pin-input';
import { checkUserFields, selectUserInfo } from "../../features/User/userSlice";
import Anchor from "../../components/Anchor";

import './LoginScreen.css'
import Timer from "../../components/Timer";
import { API_URL } from "../../config/constants";
import { GetFromLocalStorage } from "../../utils/utils";
import WaitingModal from "../../components/_ofa_component/WaitingModal";



export default function LoginScreen () {

    const appInfo = useSelector(selectAppInfo)
    const personalizations = useSelector(selectAppPersonalizations)
    const userAuth = useSelector(selectAppAuth)
    const userInfo = useSelector(selectUserInfo)
    const t = useSelector(selectTranslations);
    const missingFields = useSelector(checkUserFields)
    const dispatch = useDispatch();

    const [loginEmail, setLoginEmail] = useState("")
    const [legalCheck, setLegalCheck] = useState(false)

    const resetLoginEmail = () => {
        setLoginEmail('')
    }

    const handleUpdateLoginEmail = (e) => {
        setLoginEmail(e.target.value)
    }

    const startLoginProcess = (e) => {
        if(!legalCheck){

        }
        if(loginEmail !== "")
            dispatch(verifyAccountEmail(loginEmail))
    }

    const handleVerifyPin = (value, index) => {
        dispatch(confirmAccountEmail({email: loginEmail, pin: value}))
    }
    
    const renderError = (error) => {
        switch(error){
            case 'security_pin or security_token must be provided':
                return t.login.malformed_auth;
            break;
            case 'user verification not found':
                return t.login.missing_user;
            break;
            case 'wrong security pin':
                return t.login.wrong_pin;
            break;
            case 'security_pin provided does not match':
                return t.login.wrong_pin;
            break;
            case 'user has been suspended':
                return t.login.suspended_user;
            break;
            default:
                return t.login.malformed_auth;
        }
    }

    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get('email'))
            setLoginEmail(urlParams.get('email'))
    },[])

    return(
        <div id="loginScreen">
            { !userAuth?.auth_token? 
            <>
                { !userAuth.authPending? 
                    <div className="fh-div d-flex flex-column align-items-center justify-content-between">
                        <div className="top">
                            <img style={{maxWidth:"80%"}} src={`${API_URL}${personalizations?.logo_url}`} className="mx-auto d-block" />
                            <TitleH1 className="text-center mt-5">{t.login.login_email}</TitleH1>
                            <Text className="text-center mb-5">{appInfo?.is_ofa ? <>Registrati su <strong style={{color:'#436094'}}>thefaculty</strong> e usa la mail di contatto per accedere.</> : t.login.login_text}</Text>
                            <Text className="mb-1"><strong>E-mail*</strong></Text>
                            <div className="email_login_box">
                                <input className="email_login_input" type="email" onChange={handleUpdateLoginEmail} value={loginEmail} placeholder={''+t.login.email_placeholder}/>
                                {loginEmail?.length > 0 && <img src={cancel_img} onClick={resetLoginEmail} />}
                            </div>
                            {userAuth?.error ? 
                                <Text className="text-danger">{renderError(userAuth?.error)}</Text>
                            : ""}
                            
                        </div>
                        <div className="bottom pb-4 w-100">
                            {!appInfo?.is_ofa &&
                                <Form.Check type={'checkbox'} id={`internal_legal_check`} className="login_legal_check mb-3">
                                    <Form.Check.Input type={'checkbox'} checked={legalCheck} onChange={()=>{setLegalCheck(!legalCheck)}}/>
                                    <Form.Check.Label>
                                        {t.login.internal_legal_check_1}
                                        <Anchor href={`/static/legals/termini-e-condizioni.pdf`} target={'_blank'}><u>{t.login.internal_legal_check_terms}</u></Anchor>
                                        &nbsp;{t.login.internal_legal_check_2}
                                    </Form.Check.Label>
                                </Form.Check>
                            }
                            <Button className="w-100 mx-auto" onClick={startLoginProcess} disabled={(!legalCheck && !appInfo.is_ofa) || loginEmail === ""}>{t.login.login_button}</Button>
                        </div>
                        {
                            (appInfo.is_ofa && !GetFromLocalStorage('first_login_ofa') && !loginEmail) &&
                                <WaitingModal showClose={true} />
                        }
                    </div> :
                    <div className="fh-div d-flex flex-column align-items-center justify-content-between">
                        <div className="top">
                            <img style={{maxWidth:"80%"}} src={`${API_URL}${personalizations?.logo_url}`} className="mx-auto d-block"/>
                            <TitleH1 className="text-center mt-5">{t.login.login_code}</TitleH1>
                            <Text className="text-center">{t.login.login_code_text} {loginEmail}</Text>
                            <PinInput 
                                length={6} 
                                initialValue=""
                                type="numeric" 
                                inputMode="number"
                                onComplete={handleVerifyPin}
                                inputStyle={userAuth?.error ? {border: '2px solid red'} : ( userAuth.auth_token ? {border:'2px solid #65AA20'} : {border:'2px solid #BABDCC'})}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                className="mt-4"
                            />
                            { userAuth?.error ?
                                <small className="d-block w-100 mt-2 text-danger text-center">{renderError(userAuth?.error)}</small> : ""
                            }
                            <div className="mt-4">
                                <Text className="text-center mb-0">{t.login.code_missing}</Text>
                                <div className="d-flex justify-content-center align-items-center">
                                    <Anchor className="text-center d-block me-1" href="#" onClick={startLoginProcess} disabled={userAuth?.seconds_to_wait_next_verification > 0}><strong><u>{t.login.send_again}</u></strong></Anchor>
                                    <small>{userAuth?.seconds_to_wait_next_verification > 0 ? <>(<Timer timeout={userAuth?.seconds_to_wait_next_verification} onExpire={()=>{dispatch(resetVerificationTimeout())}} />)</> : ""}</small>
                                </div>
                            </div>
                        </div>
                        <div className="bottom pb-4 w-100">
                            <Button className="w-100 mx-auto" color="secondary" onClick={()=>dispatch(logout())}>
                                {t.login.wrong_email_used}
                            </Button>
                        </div>
                    </div>
                }
            </> : 
            <>
                {   
                    userInfo?.user_data && userAuth ?
                    <>
                        { userAuth?.admin_check_required || userInfo?.user_data?.state === "waiting_admin_check" ? 
                            <Navigate to={'/login/waiting_approval'} replace />
                        :
                            <>
                                { userInfo.user_data && userInfo.user_fields ?
                                    <>
                                    { (missingFields || !userInfo.user_data?.legal_checks?.external_legal_check) && !appInfo.is_ofa  ?
                                        <Navigate to={'/login/onboarding'} replace />
                                    :
                                        <Navigate to={userAuth?.origin_location || '/'} replace />
                                    }
                                    </> : null
                                }
                            </>
                        }
                    </> : ""
                }
            </>
            }
        </div>
    )
}