import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchConfirmAccountEmail, fetchConfirmAccountEmailWithThefacultyToken, fetchConfirmAccountEmailWithToken, fetchRemoveAuthtoken, fetchUpdateAuthToken, fetchVerifyAccountEmail } from './authApi';
import { GetFromLocalStorage, RemoveFromLocalStorage, SaveToLocalStorage } from '../../utils/utils';
import { loadUserFieldsData, loadUserProfileData, resetUserData } from '../../features/User/userSlice';

const initialState = {
  loading: false,
  auth_token: false,
  is_new_user: false,
  admin_check_required: false,
  error: null,
  origin_location: null,
  seconds_to_wait_next_verification: 0,
  authPending: false,
  refresh_auth: GetFromLocalStorage('refresh_auth') ? true : false 
}

export const checkUserAuth = createAsyncThunk(
  'auth/checkUserAuth',
  async (_,thunkAPI) => {
      const response = await fetchUpdateAuthToken();
      if (response?.success) {
          if(response?.data?.successfull_login){
            thunkAPI.dispatch(loadUserProfileData())
            thunkAPI.dispatch(loadUserFieldsData())
          }
          return response.data
      } else {
          return response?.error
      }
  }
);
export const verifyAccountEmail = createAsyncThunk(
  'auth/verifyAccountEmail',
  async (loginEmail) => {
      const response = await fetchVerifyAccountEmail(loginEmail);
      if (response?.success) {
          return response.data
      } else {
          return response?.error
      }
  }
);
export const confirmAccountEmail = createAsyncThunk(
  'auth/confirmAccountEmail',
  async (params, thunkAPI) => {
      const {email, pin} = params
      const response = await fetchConfirmAccountEmail(email,pin);
      if (response?.success) {
          if(response?.data?.successfull_login){
            thunkAPI.dispatch(loadUserProfileData())
            thunkAPI.dispatch(loadUserFieldsData())
          }
          return response.data
      } else {
          return response
      }
  }
);
export const confirmAccountEmailWithToken = createAsyncThunk(
  'auth/confirmAccountEmailWithToken',
  async (params, thunkAPI) => {
      const {login_token} = params
      const response = await fetchConfirmAccountEmailWithToken(login_token);
      if (response?.success) {
          if(response?.data?.successfull_login){
            thunkAPI.dispatch(loadUserProfileData())
            thunkAPI.dispatch(loadUserFieldsData())
          }
          return response?.data
      } else {
          return response
      }
  }
);
export const confirmAccountEmailWithThefacultyToken = createAsyncThunk(
  'auth/confirmAccountEmailWithThefacultyToken',
  async (params, thunkAPI) => {
      const {login_token} = params
      const response = await fetchConfirmAccountEmailWithThefacultyToken(login_token);
      if (response?.success) {
          if(response?.data?.successfull_login){
            thunkAPI.dispatch(loadUserProfileData())
            thunkAPI.dispatch(loadUserFieldsData())
          }
          return response?.data
      } else {
          return response
      }
  }
);
export const logout = createAsyncThunk(
  'auth/logout',
  async (_,thunkAPI) => {
      const response = await fetchRemoveAuthtoken();
      if (response?.success) {
          thunkAPI.dispatch(resetUserData())
          return response.data
      } else {
          return response
      }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setOriginLocation: (state, action) => {
        state.origin_location = action.payload;
    },
    setAdminCheckRequired: (state, action) => {
        state.admin_check_required = action.payload;
    },
    resetVerificationTimeout: (state) => {
        state.seconds_to_wait_next_verification = 0;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(verifyAccountEmail.fulfilled, (state, action) => {
            if(action.payload === "ok"){
              state.loading = false
              state.authPending = true
              state.error = null
            } else if(action.payload?.seconds_to_wait_next_verification){
              state.loading = false
              state.authPending = true
              state.seconds_to_wait_next_verification = action.payload.seconds_to_wait_next_verification
            } else if(action.payload){
              state.error = action.payload
              state.authPending = false
            }
        })
        .addCase(confirmAccountEmail.fulfilled, (state, action) => {
            if(action.payload?.successfull_login){
              state.auth_token = true
              state.is_new_user = action.payload.is_new_user
              state.admin_check_required = action.payload.admin_check_required
              state.authPending = false
              SaveToLocalStorage('refresh_auth',true)
            } else if(action.payload.error){
              state.error = action.payload.error
            }
            state.loading = false
        })
        .addCase(confirmAccountEmailWithToken.pending, (state, action) => {
            state.loading = true
            state.authPending = true
        })
        .addCase(confirmAccountEmailWithToken.fulfilled, (state, action) => {
            if(action.payload?.successfull_login){
              state.auth_token = true
              state.is_new_user = action.payload.is_new_user
              state.admin_check_required = action.payload.admin_check_required
              state.authPending = false
              SaveToLocalStorage('refresh_auth',true)
            } else if(action.payload){
              state.error = action.payload
            }
            state.loading = false
            state.authPending = false
        })
        .addCase(confirmAccountEmailWithThefacultyToken.pending, (state, action) => {
            state.loading = true
            state.authPending = true
        })
        .addCase(confirmAccountEmailWithThefacultyToken.fulfilled, (state, action) => {
            if(action.payload?.successfull_login){
              state.auth_token = true
              state.is_new_user = action.payload.is_new_user
              state.admin_check_required = action.payload.admin_check_required
              state.authPending = false
              SaveToLocalStorage('refresh_auth',true)
            } else if(action.payload){
              state.error = action.payload
            }
            state.loading = false
            state.authPending = false
        })
        .addCase(checkUserAuth.fulfilled, (state, action) => {
            if(action.payload?.successfull_login){
              state.auth_token = true
              state.refresh_auth = false
            } else if(action.payload){
              state.error = action.payload
              state.auth_token = false
              state.refresh_auth = false
              RemoveFromLocalStorage('refresh_auth')
            }
            state.loading = false
            state.authPending = false
        })
        .addCase(logout.fulfilled,(state, action) => {
            if(action.payload === "ok"){
              state.authPending = false;
              state.auth_token = false;
              state.refresh_auth = false
              RemoveFromLocalStorage('refresh_auth')
            } else if(action.payload){
              state.error = action.payload
              state.auth_token = false
              RemoveFromLocalStorage('refresh_auth')
            }
            state.loading = false
            state.authPending = false
        })
  },
})
export default authSlice.reducer
export const { resetVerificationTimeout, setOriginLocation, setAdminCheckRequired } = authSlice.actions;

export const selectAppAuthToken = (state) => state?.auth?.auth_token;
export const selectAppAuth = (state) => state?.auth;