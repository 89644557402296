import CallServer from '../../api/CallServerV2';
export async function fetchGetUserData () {
    return await CallServer({
        endpoint: '/user/get_user_data',
        needAuthenticate: true,
        isGet: true,
        retryIfFailed: true,
    })
} 
export async function fetchGetUserFields () {
    return await CallServer({
        endpoint: '/user/get_user_fields',
        needAuthenticate: true,
        isGet: true,
        retryIfFailed: true,
    })
} 
export async function fetchUpdateUserData (user_data) {
    return await CallServer({
        endpoint: '/user/update_user_fields',
        needAuthenticate: true,
        retryIfFailed: true,
        body: user_data
    })
} 
export async function fetchUpdateOnlineDate () {
    return await CallServer({
        endpoint: '/user/update_last_online',
        needAuthenticate: true,
        retryIfFailed: true,
    })
} 