import Lottie from 'lottie-react';
import hourglassAnimation from '../../../assets/images/hourglass.json'
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../services/i18n/i18nSlice';
import { Text } from '../../../components/Text';
import { TitleH3 } from '../../../components/TitleH3';

export default function LoadingScreen(){

    const t = useSelector(selectTranslations)

    return(
        <div className='d-flex flex-column text-center justify-content-center align-items-center' style={{minWidth:"100%",minHeight:"90vh"}}>
            <Lottie
                animationData={hourglassAnimation}
                autoplay={true}
                loop={100000}
                style={{width:"100px"}}
                className="d-block me-2 mb-3" 
            />  
            <TitleH3 className='mt-2'>{t.training.loading}</TitleH3>
            <Text className='mt-2'>{t.training.loading_text}</Text>
        </div>
    )
}