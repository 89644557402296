import { useSelector } from "react-redux";
import { selectAppPersonalizations } from "../services/utils/appInfoSlice";
import styled from 'styled-components'


const DefaultH2 = styled.h2`
  /* Adapt the colors based on primary prop */
  color: ${props => props.$color || '#181818'};
  font-size: 24px;
  line-height: 120%;
  font-weight: 700;
`;

export const TitleH2 = ({ size, className='', color='', children }) => {

    const personalizations = useSelector(selectAppPersonalizations);

    return (
        <DefaultH2 className={className} $color={personalizations?.h2?.color || color}>
            {children}
        </DefaultH2>
    )
}

