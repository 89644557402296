import CallServer from '../../api/CallServerV2';

export async function fetchGetFolder (params = {}) {
    return await CallServer({
        endpoint: '/user/get_folder',
        params: params,
        needAuthenticate: true,
        isGet: true,
        retryIfFailed: true
    })
} 

export async function fetchGetSubFolders (params = {}) {
    return await CallServer({
        endpoint: '/user/get_subfolders_by_parent_folder_id',
        params: params,
        needAuthenticate: true,
        isGet: true,
        retryIfFailed: true
    })
} 

export async function fetchGetVideo (params = {}) {
    return await CallServer({
        endpoint: '/get_video',
        params: params,
        needAuthenticate: true,
        isGet: true,
        retryIfFailed: true
    })
} 

export async function fetchUpdateUserVideoStatistics (params = {}) {
    return await CallServer({
        endpoint: '/update_user_video_statistics',
        params: params,
        needAuthenticate: true,
        retryIfFailed: true
    })
} 