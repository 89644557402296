import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../services/i18n/i18nSlice"

import exam_img from './assets/images/exam.svg'
import clock_img from './assets/images/clock.svg'
import { Link } from "react-router-dom"
import ExamsList from "./ExamList"

import './assets/Exam.css'
import { useEffect } from "react"
import { setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice"
import { TitleH1 } from "../../components/TitleH1"
import { selectAppInfo } from "../../services/utils/appInfoSlice"
import DesktopSheet from "../../components/_ofa_component/DesktopSheet"

export default function ExamsHistoryScreen () {

    const t = useSelector(selectTranslations)
    const appInfo = useSelector(selectAppInfo)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(setNavbarHideMobile(false))
        dispatch(setNavbarHide(false))
        dispatch(setLoading(false))
    },[])

    return (
        <section className="content_section">
            <TitleH1 className="page_title">{t.exams.page_title}</TitleH1>
            <div className="navigation_chips__container mt-4">
                <Link to={'/exams'}>
                    <div className="navigation_chip ">
                        <img src={exam_img} alt="" />
                        {t.exams.all_exams}
                    </div>
                </Link>
                <Link to={'/exams/history'}>
                    <div className="navigation_chip active">
                        <img src={clock_img} alt="" />
                        {t.exams.exams_history}
                    </div>
                </Link>
            </div> 
            <div className="mt-4">
                <div className=" mt-3">
                    <ExamsList showHistory={true} showStarted={true}/>
                </div>
            </div>
            {
                appInfo.is_ofa && 
                    <DesktopSheet />
            }
        </section>
        
    )
}