import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useLocation } from "react-router";
import { useEffect, useState, useRef } from "react";
import { setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice";
import { Link, useNavigate } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion';

import arrow_img from '../../assets/images/arrow-back.svg'
import correct_img from '../../assets/images/questions/correct.svg'
import missing_img from '../../assets/images/questions/missing.svg'
import wrong_img from '../../assets/images/questions/wrong.svg'
import structure_img from './assets/images/structure.svg'
import score_img from './assets/images/score.svg'

import { TitleH1 } from "../../components/TitleH1";
import { TitleH3 } from "../../components/TitleH3";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { useTimer } from "react-timer-hook";

export default function ExamStartScreen(){

    const [examData, setExamData] = useState(null)
    const t = useSelector(selectTranslations)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        seconds,
        minutes,
        hours,
        restart
    } = useTimer({ expiryTimestamp: new Date(Date.now() + 5*60*1000), onExpire: () => handleStartModule() });
    const seconds_ref = useRef(seconds);
    const minutes_ref = useRef(minutes);
    const hours_ref = useRef(hours);

    useEffect(() => {
        seconds_ref.current = seconds;
        minutes_ref.current = minutes;
        hours_ref.current = hours;
    }, [seconds])

    const handleStartModule = () => {
        dispatch(setLoading(true))
        navigate('/exams/perform/', {replace: true, state:{exam: examData}})
    }

    useEffect(()=>{
        dispatch(setNavbarHideMobile(true))
        dispatch(setNavbarHide(true))
        if(location.state)
            setExamData(location.state)
        dispatch(setLoading(false))
        // eslint-disable-next-line
    },[location])


    return (
        <section id="examPreviewScreen">
            <div className='section_header extended'>
                <Link to={'/exams'}><img src={arrow_img} className='pointer' alt=""/></Link>
            </div>
            {examData ?
            <>
                <div style={{maxWidth:"500px", minWidth:"320px"}} className="mx-auto">
                    <TitleH1 className="text-center">{examData?.user_modules[0]?.module_name}</TitleH1>
                    <Text className="text-center">{examData?.user_modules[0]?.description}</Text>
                    <div className="text-center"><span >{t.exam.module} {examData?.user_modules[0].module_number +1} {t.exam.of} {examData.exam?.modules_count}</span></div>
                    <div className="border mt-3 mb-2 p-3" style={{borderRadius:"16px"}}>
                        <TitleH3  className="text-center"><img src={structure_img} alt="" className="me-2"/> {t.exam.structure}</TitleH3>
                        <div className="mt-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <Text className="mb-0">{t.exam.duration}</Text>
                                <span><strong>{examData?.user_modules[0]?.time}</strong></span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <Text className="mb-0">{t.exam.questions}</Text>
                                <span><strong>{examData?.user_modules[0]?.questions.length}</strong></span>
                            </div>
                        </div>
                    </div>
                    <div className="border mt-3 mb-2 p-3" style={{borderRadius:"16px"}}>
                        <TitleH3 className="text-center"><img src={score_img} alt="" className="me-2"/>{t.exam.scoring}</TitleH3>
                        <div className="mb-2  mt-2  ">
                            <div className="d-flex justify-content-around">
                                <div><img style={{verticalAlign:"sub"}} src={correct_img} className="me-1" alt="" /><strong>{examData?.user_modules[0]?.correct_answer_score}</strong> pt</div>
                                <div><img style={{verticalAlign:"sub"}} src={missing_img} className="me-1" alt="" /><strong>{examData?.user_modules[0]?.omitted_answer_score}</strong> pt</div>
                                <div><img style={{verticalAlign:"sub"}} src={wrong_img} className="me-1" alt="" /><strong>{examData?.user_modules[0]?.wrong_answer_score}</strong> pt</div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div>
                    <Text className="text-center">{t.exam.module_start_time} <strong><span>{minutes}</span>:<span>{seconds}</span> min</strong></Text>
                    <Button className="mx-auto" onClick={handleStartModule}>{t.exam.start_module}</Button>
                </div>
            </>
            : null}
        </section>
    )
}