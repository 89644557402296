import { useSelector } from "react-redux";
import { selectAppPersonalizations } from "../services/utils/appInfoSlice";
import styled from 'styled-components'
import { DEVICE } from "../config/constants";


const DefaultH1 = styled.h1`
  /* Adapt the colors based on primary prop */
  color: ${props => props.$color || "#181818"};
  font-size: 24px;
  line-height: 120%;
  font-weight: 700;
  @media ${DEVICE.tablet} { 
    font-size: 32px
  }
`;

export const TitleH1 = ({ size, className='', color='', children }) => {

    const personalizations = useSelector(selectAppPersonalizations);

    return (
        <DefaultH1  className={className} $color={personalizations?.h1?.color || color}>
            {children}
        </DefaultH1>
    )
}

