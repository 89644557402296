import { useEffect, useRef } from 'react';
import { useTimer } from 'react-timer-hook';

export default function Timer(props){

    const {
        seconds,
        minutes,
        hours,
        restart,
    } = useTimer({ expiryTimestamp: props.timeout || undefined, onExpire: () => {props.onExpire()} });

    const seconds_ref = useRef(seconds);
    const minutes_ref = useRef(minutes);
    const hours_ref = useRef(hours);

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    useEffect(() => {
        seconds_ref.current = seconds;
        minutes_ref.current = minutes;
        hours_ref.current = hours;
    }, [seconds])

    useEffect(()=>{
        if(props.timeout){
            let time = new Date();
            time.setSeconds(time.getSeconds() + props.timeout); 
            restart(time);
        }
    },[props.timeout])

    return (
        <span className='timer'>
            {hours > 0 ? <span>{formatTime(hours)}h </span> : ""}
            {minutes > 0 ? <span>{formatTime(minutes)}m </span> : ""}
            {seconds > 0 ? <span>{formatTime(seconds)}s</span> : "0s"}
        </span>
    )

}