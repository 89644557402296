export const SelectOption = (props) =>
    !props.isDisabled ? (
        props.isMulti ?
            <div {...props.innerProps} className={`${props.isSelected ? 'selected' : ''} custom_react-select-option mb-1`}>
                <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </div>
        : <label {...props.innerProps} className={`${props.isSelected ? 'selected' : ''} custom_react-select-option d-block w-100 mb-1`} >{props.label}</label>
    ) : null;