
import { Link } from 'react-router-dom';
import {Button} from '../../components/Button';

import error_img from '../../assets/images/error.svg'
import {TitleH1} from '../../components/TitleH1';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../services/i18n/i18nSlice';
import {Text} from '../../components/Text';


export default function ErrorScreen (props) {

    const t = useSelector(selectTranslations);

    return(
        <div id="errorScreen">
            <div className="fh-div d-flex flex-column align-items-center justify-content-between">
                <div className="top">
                    <img src={error_img} className="mx-auto d-block" style={{maxWidth:'150px'}}/>
                    <TitleH1 className="text-center mt-2">{props?.error_title ? props?.error_title : t.error.error_page_title}</TitleH1>
                    <Text className="text-center mt-2">{props?.error_text ? props?.error_text : t.error.error_page_text}</Text>
                    {props?.redirect ?
                        <Link to={props?.redirect}>
                            <Button type={'primary'} className="mt-3 mx-auto">{t.error.back_home}</Button>
                        </Link>
                    : ""}
                </div>
            </div>
        </div>
    )
}