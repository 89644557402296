import { useSelector } from "react-redux";
import { selectAppPersonalizations } from "../services/utils/appInfoSlice";
import styled from 'styled-components'
import { DEVICE } from "../config/constants";


const DefaultText = styled.p`
  /* Adapt the colors based on primary prop */
  color: ${props => props.$color || '#181818'};
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  @media ${DEVICE.tablet} { 
    font-size: 18px
  }
`;

export const Text = ({ size, className='', color='', children }) => {

    const personalizations = useSelector(selectAppPersonalizations);

    return (
        <DefaultText className={className} $color={personalizations?.text?.color || color}>
            {children}
        </DefaultText>
    )
}

