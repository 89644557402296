import { configureStore } from '@reduxjs/toolkit'
import i18nReducer from "../services/i18n/i18nSlice";
import appInfoReducer from '../services/utils/appInfoSlice';
import authReducer from '../services/auth/authSlice';
import userReducer  from '../features/User/userSlice';
import errorSlice from '../features/Error/errorSlice';
import globalSlice from '../services/global/globalSlice';
import examSlice from '../features/Exam/examSlice';

export const store = configureStore({
  reducer: {
    global: globalSlice,
    auth: authReducer,
    i18n: i18nReducer,
    appInfo: appInfoReducer,
    userInfo: userReducer,
    errorManager: errorSlice,
    currentExam: examSlice
  },
});