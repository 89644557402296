import {Text} from "../Text";
import {TitleH2} from "../TitleH2";
import Modal from 'react-bootstrap/Modal';
import {Button} from "../Button";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";

import img_warning from '../../assets/images/warning.svg'
import img_close from '../../assets/images/close.svg'

export default function EndModal ({props}) {

    const {show, onConfirm, onSecondary, onClose, title, text, confirmCta, secondaryCta} = props
    const t = useSelector(selectTranslations)

    return (
        <Modal
            show={show}
            onHide={onClose}
            keyboard={false}
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-end">
                    <img src={img_close} alt="" className="pointer" onClick={onClose} />
                </div>
                <div className="d-flex justify-content-center">
                    <img src={img_warning} alt="" className="mx-center" />
                </div>
                <TitleH2 className="text-center mt-2">{title}</TitleH2>
                <Text className="text-center mb-4">{text}</Text>
                {onConfirm !== null ? 
                    <Button className="mt-2 w-100 mx-auto" onClick={onConfirm} color="primary">{confirmCta || t.modals.confirm}</Button>
                    : null
                }
                {onClose !== null ? 
                    <Button className="mt-2 w-100 mx-auto" onClick={onSecondary} color="secondary">{secondaryCta || t.modals.close}</Button>
                    : null
                }
            </Modal.Body>
        </Modal>
    )
}