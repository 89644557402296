import React from "react"

import arrow_left from '../assets/images/arrow_previous.svg'
import arrow_right from '../assets/images/arrow_next.svg'
import arrow_back from '../../../assets/images/left.svg'

import "./Navigation.css";

import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";

import icon_missing_answer from '../../../assets/images/questions/missing.svg'
import icon_correct_answer from '../../../assets/images/questions/correct.svg'
import icon_wrong_answer from '../../../assets/images/questions/wrong.svg'
import { isCorrectAnswer } from "../examUtils";

export default function NavigationReview (props) {


    const t = useSelector(selectTranslations)

    const changeQuestion = (index, module_number) => {
        if(!props.navigationData.is_linear_question_navigation)
            if(!props?.navigationData?.is_linear_module_navigation || (props?.navigationData?.is_linear_module_navigation && module_number === props?.navigationData?.current_module_number))
                props.handleChangeQuestion(index);
    }

    return (
        <div className="col-3 navigation_bar pb-5">
            <div className="d-flex align-items-center pointer" onClick={props.navigateBack}>
                <div className=" ms-1"><img src={arrow_back} /> </div>
                <u><span>{t.buttons.back}</span></u>
            </div>
            {
                props.userModules[props?.navigationData?.currentModule]?.module_name ? 
                    <div className="mb-0 mt-3">
                        <p className="text-black mb-0" style={{fontWeight: '800'}}>Modulo {props.userModules[props?.navigationData?.currentModule].module_number +1}: {props.userModules[props?.navigationData?.currentModule].module_name} </p>
                    </div>
                : ""
            }
            <div className="mt-3">
                <div className="d-flex w-100 justify-content-between">
                        <div className="d-flex pointer" onClick={()=>{props.handleChangeModule(props.navigationData.currentModule-1)}} style={{visibility: props.navigationData.currentModule != 0 ? 'visible' : 'hidden'}}>
                            <div className="arrow_navigation me-1"><img src={arrow_left} /> </div>
                            <span>{t.exam.module} {props.navigationData.currentModule}</span>
                        </div>
                        <div className="d-flex pointer" onClick={()=>{props.handleChangeModule(props.navigationData.currentModule+1)}} style={{visibility: props.navigationData.currentModule+1 < props.userModules?.length ? 'visible' : 'hidden'}}>
                            <span>{t.exam.module} {props.navigationData.currentModule+2}</span>
                            <div className="arrow_navigation ms-1"><img src={arrow_right} /> </div>
                        </div>
                </div>
                <div className="mt-3 questions_nav">
                    {
                        props?.questions?.map((question, index) => {
                            if(question.module_number === props.navigationData.currentModule){
                                let has_answer = ((question.selected_answer_number !== null && question.selected_answer_number !== undefined && !isNaN(question.selected_answer_number)) || (question.selected_answer_numbers !== null && question.selected_answer_numbers !== undefined && question?.selected_answer_numbers?.length !== 0 )) ? true : false;
                                return (
                                    <div key={index} className={"question_el_external " + (question.isBlockQuestion ? "block_wrapped " : "") + (question.isFirstBlock ? "block_wrapped_first " : "") + (question.isLastBlock ? "block_wrapped_last " : "") }>
                                        <div key={index} onClick={() => {changeQuestion(question.question_id, question.module_number)}} className={"question_el " + (has_answer ? "answered " : "") + (question.is_uncertain ? "is_uncertain " : "" ) + (question.question_id === props.navigationData.currentQuestion ? "current " : "") + (props.navigationData.is_linear_module_navigation && (props.navigationData?.current_module_number !== question.module_number) ? "disabled_question " : "")}>
                                            {parseInt(index)+1}
                                            {
                                            !has_answer ?
                                                <img src={icon_missing_answer} className="answer_navigation_result" />
                                            : (isCorrectAnswer(question) ? <img src={icon_correct_answer} className="answer_navigation_result" /> : <img src={icon_wrong_answer} className="answer_navigation_result" />)
                                            }
                                        </div>
                                        
                                    </div>
                                )
                            } else return null
                        })
                    }
                </div>
            </div>
            
        </div>
    )
}