import { useEffect, useState } from "react"
import "./Question.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../services/i18n/i18nSlice";
import { Form, OverlayTrigger, Popover, FormCheck } from "react-bootstrap";

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import SecureImage from "../../../components/_partials/SecuredImage";
import { isMobile } from "react-device-detect";
import ImageZoom from "../../../components/ImageZoom/ImageZoom";
import { API_URL } from "../../../config/constants";

export default function Question(props){

    const t = useSelector(selectTranslations)
    const [imageLoaded, setImageLoaded] = useState(false)

    const handleAnswerBaseQuestion = (event) =>{
        const target = event.target;
        const value = target.type === 'checkbox'  ? target.checked : target.value;
        const name = target.name;
        props.handleAnswerQuestion(props.data.question_type, props.data, value);
    }
    const handleAnswerMultiQuestion = (event) => {
        const target = event.target;
        const checked = target.checked;
        const value = target.value;
        props.handleAnswerQuestion(props.data.question_type, props.data, value, checked);
    }
    const handleAnswerBlockQuestion = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        props.handleAnswerQuestion("block", props.data, value);
    }
    const resetAnswer = () => {
        if(props.data.question_type === "base")
            props.handleAnswerQuestion("base", props.data, null);
        else if (props.data.question_type === "block")
            props.handleResetBlockQuestion(props.data.question_number)
    }

    const formatText = (original_text = "") => {
        return original_text?.replaceAll("\n", '<br />')?.replaceAll("$$","$")
    }

    const hasAnswer = props?.data?.selected_answer_number !== null && props?.data?.selected_answer_number !== undefined

    useEffect(()=>{
        setImageLoaded(false)
    },[props?.data?.image_url])

    return (
        <div className="col-12 col-md-9 exam_question_container px-3 px-md-4 pb-5">
            {
                props.data? 
                    <>
                        {!isMobile ? <p className="text-black fw-bold">{t.question.question} {parseInt(props.data.virtual_question_number)} </p> : null}
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: props.data.text }} />
                        {
                            props.data.paper && props.data.virtual_question_number != undefined ?
                                <div className="reader_preview" onClick={props.handleOpenReader}>
                                    <p style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{ __html: formatText(props.data.paper) }} />
                                </div>
                            : ""
                        }
                        { props.data.image_url ?
                            <> 
                                <ImageZoom>
                                    <SecureImage 
                                        imageUrl={`${API_URL}${props.data.image_url}`} 
                                        alt="Question Image" 
                                        className="mt-3 w-100" 
                                    />
                                </ImageZoom>
                                
                                <small className="d-block text-secondary">{t.question.click_on_image}</small>
                            </>
                        : ""}
                        {
                            props.data.additional_material_instructions ? <p className="mt-2 text-secondary">{props.data.additional_material_instructions}</p> : ""
                        }
                        <div className="d-flex mt-3 align-items-end justify-content-between">
                            {
                                ( hasAnswer ) && props.data.question_type !== "multi" ? 
                                    <span className="reset_answer w-100 text-end" onClick={resetAnswer}>{t.exam.reset_answer}</span>
                                : ""
                            }
                        </div>
                        {
                            props.data.question_type === "base" ?
                                <div className="answers_list">
                                    {props.data.answers.map((answer) => {
                                        return(
                                            <label className={"answer " + ( answer.answer_number == props.data.selected_answer_number ? "selected" : "" )} key={answer.answer_number} htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}>
                                                
                                                <FormCheck>
                                                    <FormCheck.Input  
                                                        type={"radio"}
                                                        className="hidden_radio"
                                                        name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        value={answer.answer_number} 
                                                        onChange={handleAnswerBaseQuestion}
                                                        checked={answer.answer_number == props.data.selected_answer_number ? true : false}
                                                    />
                                                    <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} >
                                                        <span className="answer_label_number">
                                                            {String.fromCharCode(65 + answer.answer_number)}.
                                                        </span>
                                                        <span dangerouslySetInnerHTML={{ __html: answer.text }}/>
                                                    </FormCheck.Label>
                                                </FormCheck>
                                            </label>
                                        )
                                    })}
                                </div>
                            : ""
                        }
                        {
                            props.data.question_type === "multi" || props.data.question_type === "survey"?
                                <div className="answers_list">
                                    {props.data.answers.map((answer) => {
                                        return (
                                            <label className={"answer " + (props.data.selected_answer_numbers && props.data.selected_answer_numbers.includes(parseInt(answer.answer_number)) ? "selected" : "")}  key={answer.answer_number} htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}>
                                                
                                                <FormCheck>
                                                    <FormCheck.Input  
                                                        type={"checkbox"}
                                                        name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                        value={answer.answer_number} 
                                                        onChange={handleAnswerMultiQuestion}
                                                        checked={props.data.selected_answer_numbers !== null && props.data.selected_answer_numbers.includes(parseInt(answer.answer_number)) ? true : false}
                                                />
                                                    <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`} dangerouslySetInnerHTML={{ __html: answer.text }} />
                                                </FormCheck>
                                            </label>
                                        )
                                    })}
                                </div>
                                : ""
                        }
                        {
                            props.data.question_type === "block" ?
                                <div className="answers_list">
                                    <div className="answers_list">
                                        {props.data.answers.map((answer) => {
                                            return(
                                                <label className={"answer " + ( answer.answer_number == props.data.selected_answer_number ? "selected" : "" )} key={answer.answer_number} htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}>
                                                    
                                                    <FormCheck>
                                                        <FormCheck.Input  
                                                            type={"radio"}
                                                            className="hidden_radio"
                                                            name={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                            id={`answer-${props.data.question_number}-${answer.answer_number}`}
                                                            value={answer.answer_number} 
                                                            onChange={handleAnswerBlockQuestion}
                                                            checked={answer.answer_number == props.data.selected_answer_number ? true : false}
                                                        />
                                                        <FormCheck.Label htmlFor={`answer-${props.data.question_number}-${answer.answer_number}`}  >
                                                            <span className="answer_label_number">
                                                                {String.fromCharCode(65 + answer.answer_number)}.
                                                            </span>
                                                            <span dangerouslySetInnerHTML={{ __html: answer.text }}/>
                                                        </FormCheck.Label>
                                                    </FormCheck>
                                                </label>
                                            )
                                        })}
                                    </div>
                                </div>
                                : ""
                        }
                    </>
                : ""
            }
        </div>
    )
}